// src/components/PerformanceGoalEntry/GoalTableSummary.tsx
import React, { useEffect } from 'react';
import { Input, Table } from 'antd';
import { calculatedFieldModel } from './GoalTable';

interface GoalTableSummaryProps {
  total: calculatedFieldModel
}

const GoalTableSummary: React.FC<GoalTableSummaryProps> = ({ total }) => {
  useEffect(() => {
    console.log(total)
  }, [total])
  
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>Toplam</Table.Summary.Cell>
      <Table.Summary.Cell index={1} />
      <Table.Summary.Cell index={2}>
        <Input value={total.weight} suffix="%" disabled />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} />
      <Table.Summary.Cell index={4} />
      <Table.Summary.Cell index={5} />
      <Table.Summary.Cell index={6} />
    </Table.Summary.Row>
  );
};

export default GoalTableSummary;
