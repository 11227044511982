import React, { ReactNode } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';

interface PrivateRouteProps extends RouteProps {
  component?: React.ComponentType<any>;
  children?: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, children, ...rest }) => {
  const isAuthenticated = useSelector((state: RootState) => state.persisted.isLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          Component ? <Component {...props} /> : children
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
