import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'antd';
import DepartmentOKREntryModal from './DepartmentOKREntryModal';

import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setError } from '../../features/app/appSlice';
import { RootState } from '../../app/store';
import { PerformanceGoalDto, useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery } from '../../api/services/PerformanceGoal';
import moment from 'moment';
import { DepartmentOkrMasterDto, StrategicOkrDetailDto, useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery } from '../../api/services/DepartmentOKR';

const columns = [
  { title: 'Performans Dönemi', dataIndex: 'strategicOKRDetails', key: 'strategicOKRDetails', render: (data: StrategicOkrDetailDto[]) => { return `${data?.[0].period?.periodName} (${moment(data[0].period?.startDate).format("DD/MM/YYYY")} - ${moment(data?.[0].period?.startDate).format("DD/MM/YYYY")})` } },
  { title: 'OKR Sayısı', dataIndex: 'okrDetails', key: 'okrDetails', render: (data: any) => { return `${data.length} performans kaydı` } },
];

const DepartmentOKREntryPage: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [getDepartmentOKRs, { data: departmentOkrs }] = useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery()
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const [selectedRow, setselectedRow] = useState<DepartmentOkrMasterDto>()
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      fetchPerformanceGoals()
    }
  }, [user])

  const fetchPerformanceGoals = async () => {
    try {
      dispatch(setLoading(true));
      await getDepartmentOKRs({
        companyGroupId: user?.company?.companyGroupId ?? ""
      }).unwrap()
    } catch (err: any) {
      if (err.status != "PARSING_ERROR")
        dispatch(setError(t('Performans hedefleri çekilirken hata oluştu')));
    } finally {
      dispatch(setLoading(false));
    }
  }

  const onModalClose = async () => {
    await fetchPerformanceGoals()
    setModalVisible(false)
    form.resetFields()
  }

  const onRowClick = (values: any) => {
    setselectedRow(values)
    setModalVisible(true)
  }

  const addNewClicked = () => {

    form.resetFields()
    setselectedRow(undefined)
    setModalVisible(true)
  }



  return (
    <>
      <Button type="primary" onClick={() => addNewClicked()} style={{ marginBottom: 16 }}>
        Departman OKR Ekle
      </Button>
      <Table dataSource={departmentOkrs}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })} />
      <DepartmentOKREntryModal
        form={form}
        visible={modalVisible}
        onClose={() => onModalClose()}
        initialValues={{
          ...selectedRow,
          okrId: selectedRow?.strategicOKRId ?? undefined,
          rows: selectedRow?.okrDetails?.map(k => ({
            departmentOkr: k.departmentOKR ?? "",
            okr: k.strategicOKRDetailId ?? "",
            okrWeight: k.okrWeight ?? "",
            targetResult: k.targetResult ?? "",
            targetSource: k.targetSource ?? ""
          }))
        }} />
    </>
  );
};

export default DepartmentOKREntryPage;