import React, { useEffect, useRef } from 'react';
import { Breadcrumb, Layout, Menu } from 'antd';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import Users from './Users/Users';
import Companies from './Companies/Companies';
import Profile from './Profile';
import PrivateRoute from '../components/PrivateRoute';
import AppHeader from '../components/Header';
import { useTranslation } from 'react-i18next';
import { useLazyGetApiAuthMeQuery, usePostApiAuthRefreshTokenMutation } from '../api/services/auth';
import { setLoading } from '../features/app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../features/auth/authSlice';
import SideMenu from '../components/SideMenu';
import Dashboard from './Dashboard';
import PeriodDefinition from './Periods/PeriodDefinition';
import TargetAudienceSelection from './TargetAudience/TargetAudienceSelection';
import ScaleSelection from './ScaleSelection/ScaleSelection';
import PerformanceSystemPage from './PerformanceSystem/PerformanceSystemPage';
import PerformanceGoalEntryPage from './PerformanceGoalEntry/PerformanceGoalEntryPage';
import ParametersPage from './Parameters/ParametersPage';
import SystemRoleManagement from './Roles/SystemRoleManagement';
import UserRoleDefinitions from './Roles/UserRoleDefinitions';
import OneOnOneMeetingSchedule from './OneOnOneMeetingSchedule/OneOnOneMeetingSchedule';
import PerformanceCardScreen from './PerformanceCardScreen/PerformanceCardScreen';
import StrategicOKREntryPage from './StrategicOKREntry/StrategicOKREntryPage';
import DepartmentOKREntryPage from './DepartmentOKREntry/DepartmentOKREntryPage';
import MyPerformanceCardScreen from './MyPerformanceCardsScreen/MyPerformanceCardsScreen';
import { RootState } from '../app/store';
import { logout, setAccessToken, setRefreshToken, toggleLoggedIn } from '../app/persistedSlice';

const { Sider, Content } = Layout;



const Home: React.FC = () => {
  const { t } = useTranslation();
  let { path, url } = useRouteMatch();
  const location = useLocation();
  const [getMyData, { data: userData, isLoading: myDataLoading }] = useLazyGetApiAuthMeQuery()
  const dispatch = useDispatch();




  useEffect(() => {
    dispatch(setLoading(myDataLoading))
  }, [myDataLoading])

  useEffect(() => {
    getMyData()
  }, [])

  useEffect(() => {
    if (userData) {
      dispatch(setUserInfo(userData))
    }
  }, [userData])

  const generateBreadcrumbs = () => {
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const breadcrumbs = pathSnippets.map((_, index) => {
      const url = `${pathSnippets.slice(0, index + 1).join('/')}`;
      return <Breadcrumb.Item key={url}><Link to={url}>{t(url)}</Link></Breadcrumb.Item>;
    });
    return (
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item key="home"><Link to="/">{t('home')}</Link></Breadcrumb.Item>
        {breadcrumbs}
      </Breadcrumb>
    );
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <AppHeader />
      <Layout>
        <PrivateRoute path={path}>
          <Sider width={250} style={{ margin: 10, borderRadius: 10, overflow: "hidden" }}>
            <SideMenu />
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content style={{ margin: '16px', display: 'flex', flexDirection: 'column' }}>
              {generateBreadcrumbs()}
              <div style={{
                padding: 24,
                background: '#fff',
                borderRadius: '16px',
                flexGrow: 1,
                overflow: 'auto'
              }}>
                <Switch>
                  <Route path={`/home`} component={Dashboard} />
                  <Route path={`/users`} component={Users} />
                  <Route path={`/companies`} component={Companies} />
                  <Route path={`/profile`} component={Profile} />
                  <Route path={`/perioddefinition`} component={PeriodDefinition} />
                  <Route path={`/targetaudiencedefinition`} component={TargetAudienceSelection} />
                  <Route path={`/scaledefinition`} component={ScaleSelection} />
                  <Route path={`/performancesystemsdefinition`} component={PerformanceSystemPage} />
                  <Route path={`/corporategoalcardentry`} component={PerformanceGoalEntryPage} />
                  <Route path={`/parameters`} component={ParametersPage} />
                  <Route path={`/systemrole`} component={SystemRoleManagement} />
                  <Route path={`/userrole`} component={UserRoleDefinitions} />
                  <Route path={`/oneononemeetingschedule`} component={OneOnOneMeetingSchedule} />
                  <Route path={`/performancecardscreation`} component={PerformanceCardScreen} />
                  <Route path={`/strategicokrentry`} component={StrategicOKREntryPage} />
                  <Route path={`/departmentokrentry`} component={DepartmentOKREntryPage} />
                  <Route path={`/myperformancecardsstrategicokrs`} component={MyPerformanceCardScreen} />
                </Switch>
              </div>
            </Content>
          </Layout>
        </PrivateRoute>
      </Layout>
    </Layout>
  );
};

export default Home;
