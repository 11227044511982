import { Button, Card, Progress, Slider, Space } from "antd";
import { ActivePerformanceCardDto, UserKrDetailsDto, UserKrUpdateDto } from "../../../api/services/performanceCards";
import {
    EditOutlined,
    EyeInvisibleOutlined,
    HistoryOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import KRInputModal from "./Modal/KRInputModal";
import ProgressInputModal from "./Modal/ProgressInputModal";
import moment from "moment";

export interface IPerformanceCardKR {
    card: ActivePerformanceCardDto | undefined
    index: number
    kr: UserKrDetailsDto | undefined
    update: () => void
}

const PerformanceCardKR: React.FC<IPerformanceCardKR> = ({ index, card, kr, update }) => {

    const [progressInputActive, setProgressnputActive] = useState(false)
    const [progress, setProgress] = useState<UserKrUpdateDto[]>([])
    const [updateProgress, setUpdateProgress] = useState<UserKrUpdateDto | undefined>(undefined)

    useEffect(() => {
        if (kr) {
            setProgress(kr.updates ?? [])
        }
    }, [kr])

    return (
        <Card title={
            <div className="flex flex-row justify-between items-center bg-yellow-400 rounded-md overflow-hidden">
                <div className="flex flex-row">
                    <div className="bg-blue-500 w-[30px] -my-[3px]">
                        <h2 className="text-center text-white mt-[2px]">{index + 1}</h2>
                    </div>
                    <h2 className="ml-2">{kr?.description}</h2>
                </div>
                <div className="flex flex-row items-center">
                    <Progress percent={kr?.progress ?? 0} percentPosition={{ align: 'start', type: 'outer' }} className="w-[200px]" />
                    <div className="flex flex-row ml-2">
                        <Button type="primary" onClick={() => {
                            setProgressnputActive(true)
                        }} className="text-black bg-transparent border-0 rounded-none">
                            <div className="flex flex-col justify-center items-center">
                                <CheckCircleOutlined style={{ width: (kr?.updates ?? []).length > 0 ? 10 : 20 }} />
                                {(kr?.updates ?? []).length > 0 &&
                                    <text className="text-[8px]">{(kr?.updates ?? []).length} Kez</text>
                                }
                            </div>
                        </Button>
                        <Button type="primary" onClick={() => { }} className="text-black bg-transparent border-0 rounded-none">
                            <HistoryOutlined />
                        </Button>
                        {kr?.isPrivate &&
                            <Button type="primary" onClick={() => { }} className="text-black bg-transparent border-0 rounded-none">
                                <EyeInvisibleOutlined />
                            </Button>
                        }
                        <Button type="primary" onClick={() => {
                            update()
                        }} className="text-black bg-transparent border-0 rounded-none">
                            <EditOutlined />
                        </Button>
                    </div>
                </div>
            </div>
        }
            bordered={false}
            className="w-full mt-2"
            style={{ borderRadius: 0, boxShadow: "none" }}
            styles={{
                body: {
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 10
                },
                header: {
                    height: 30,
                    minHeight: 40,
                    border: 0,
                    padding: 0,
                }
            }}>

            <Space direction="vertical" size={"small"} className="w-full">
                {progress
                    .map((k: UserKrUpdateDto, i) => (
                        <Card title={null}
                            bordered={false}
                            onClick={() => {
                                setUpdateProgress(k)
                                setProgressnputActive(true)
                            }}
                            style={{ boxShadow: "none" }}
                            className="w-full mt-2 bg-orange-300 rounded-md overflow-hidden"
                            styles={{
                                body: {
                                    padding: 5
                                },
                                header: {
                                    height: 30,
                                    minHeight: 40,
                                    border: 0,
                                    padding: 0,
                                }
                            }}>
                            <p className="px-2">{k.description}</p>
                        </Card>
                    ))}
            </Space>
            <ProgressInputModal
                initialData={updateProgress}
                isActive={progressInputActive}
                setActive={(status) => { setProgressnputActive(status) }}
                initialProgress={kr?.progress ?? 0}
                kr={kr}
                newProgressAdded={prg => {
                    setProgress([...progress, prg])
                    setProgressnputActive(false)
                }} />
        </Card >
    );
}

export default PerformanceCardKR;
