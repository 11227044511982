import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Select, Input, Popover, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PerformanceSystemForm from './PerformanceSystemForm';
import { PerformanceSystemType, PerformanceSystemTypeIndex } from '../../enums/PerformanceSystemType';
import moment from 'moment';
import { PerformanceSystemDto, PerformanceSystemType as TypeAsModel, useLazyGetApiPerformanceSystemQuery } from '../../api/services/performanceSystem';
import { ParameterDto } from '../../api/services/parameters';
import { DataItem } from '../../models/DataItem';
import { extractParam } from '../../helper/paramHelper';

interface PerformanceSystemModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (values: any) => void;
  initialValues: any | null;
  parameters?: ParameterDto[];
}

const PerformanceSystemModal: React.FC<PerformanceSystemModalProps> = ({ parameters, visible, onCancel, onSave, initialValues }) => {
  const [form] = Form.useForm();
  const [selectedSystemType, setSelectedSystemType] = useState<PerformanceSystemTypeIndex | null>(null);
  const [userRoles, setUserRoles] = useState<DataItem[]>([])

  useEffect(() => {
    if (parameters) {
      setUserRoles(extractParam(parameters, 2))
    }
  }, [parameters])

  useEffect(() => {
    if (initialValues && userRoles.length > 0) {

      if (initialValues?.performanceSystemType == 1) {
        initialValues = { ...initialValues, "okrShape": initialValues?.okrDetails[0].okrShapeId ?? "" }
        initialValues = { ...initialValues, "okrRelation": initialValues?.okrDetails![0].okrRelationshipId ?? "" }
        initialValues = { ...initialValues, "okrTypes": initialValues?.okrDetails![0].okrTypeIds ?? "" }
        initialValues = { ...initialValues, "okrWeighting": initialValues?.okrDetails![0].okrWeightId ?? "" }
        initialValues = { ...initialValues, "okrPrivacy": initialValues?.okrDetails![0].okrPrivacyId ?? "" }
        initialValues = { ...initialValues, "okrTransparency": initialValues?.okrDetails![0].okrTransparencyId ?? "" }
        initialValues = { ...initialValues, "okrProgression": initialValues?.okrDetails![0].okrProgressId ?? "" }
      } else if (initialValues?.performanceSystemType == 2) {

        initialValues = { ...initialValues, "kpiRelation": initialValues?.kpiDetails[0].kpiRelationshipId ?? "" }
        initialValues = { ...initialValues, "kpiGoalTypes": initialValues?.kpiDetails[0].targetTypesIds ?? "" }
        initialValues = { ...initialValues, "kpiGoalCalculationSource": initialValues?.kpiDetails[0].goalTargetMeasurementSourceIds ?? "" }
        initialValues = { ...initialValues, "kpiGoalCalculationType": initialValues?.kpiDetails[0].goalMeasurementTypeId ?? "" }
        initialValues = { ...initialValues, "kpiCompentencySelection": initialValues?.kpiDetails[0].compentencyId ?? "" }
      }
      form.setFieldsValue(initialValues);
      setSelectedSystemType(initialValues?.performanceSystemType ?? 1 as TypeAsModel);
    }
  }, [initialValues, form, userRoles]);

  const handleSystemTypeChange = (value: PerformanceSystemTypeIndex) => {

    setSelectedSystemType(value);
  };

  const content = (
    <div style={{ width: 300 }}>
      <h4>Sayfa Bilgisi</h4>
      <p>Bu sayfada performans sistemlerini tanımlayabilir ve yönetebilirsiniz.</p>
      <p>OKR veya KPI bazlı performans sistemlerini burada tanımlayarak, ilgili parametreleri belirleyebilirsiniz.</p>
      <p>Sistemin nasıl çalıştığını ve hangi parametrelerin gerekli olduğunu burada yapılandırabilirsiniz.</p>
    </div>
  );

  const getNo = () => {
    return `PS-${moment().format("yyyy-MM-DD-mm")}`;
  }

  return (
    <Modal
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{initialValues ? "Performans Sistemi Güncelle" : "Performans Sistemi Ekle"}</span>
          <Popover content={content} title="Sayfa Bilgisi" trigger="click">
            <Button
              shape="circle"
              icon={<InfoCircleOutlined />}
              style={{
                marginRight: 30,
                marginTop: -8,
                zIndex: 1000,
              }}
            />
          </Popover>
        </div>
      }
      visible={visible}
      onCancel={onCancel}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
              Kaydet
            </Button>
            <Button onClick={onCancel}>
              İptal
            </Button>
          </Space>
        </div>
      }
      width={1000}
      bodyStyle={{ maxHeight: '65vh', overflowY: 'auto', paddingRight: '16px' }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        initialValues={initialValues || { systemNumber: getNo() }}
      >
        <div style={{ display: 'flex', gap: '16px' }}>
          <Form.Item name="systemNumber" label="Sistem No" style={{ flex: 1 }}>
            <Input />
          </Form.Item>
          <Form.Item name="systemName" label="Sistem Adı" style={{ flex: 1 }}>
            <Input />
          </Form.Item>
          <Form.Item name="performanceSystemType" label="Performans Sistemi" style={{ flex: 1 }}>
            <Select onChange={handleSystemTypeChange}>
              <Select.Option value={PerformanceSystemTypeIndex.OKR}>{PerformanceSystemType.OKR}</Select.Option>
              <Select.Option value={PerformanceSystemTypeIndex.KPI}>{PerformanceSystemType.KPI}</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <PerformanceSystemForm systemType={selectedSystemType}
          initialValues={initialValues}
          parameters={parameters} />
      </Form>
    </Modal>
  );
};

export default PerformanceSystemModal;
