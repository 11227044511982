import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiRolePermissionRolesByCompanyGroupId: build.query<
      GetApiRolePermissionRolesByCompanyGroupIdApiResponse,
      GetApiRolePermissionRolesByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles/${queryArg.companyGroupId}`,
      }),
    }),
    getApiRolePermissionPermissionsByCompanyGroupId: build.query<
      GetApiRolePermissionPermissionsByCompanyGroupIdApiResponse,
      GetApiRolePermissionPermissionsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/permissions/${queryArg.companyGroupId}`,
      }),
    }),
    postApiRolePermissionAssign: build.mutation<
      PostApiRolePermissionAssignApiResponse,
      PostApiRolePermissionAssignApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign`,
        method: "POST",
        body: queryArg.rolePermissionDto,
      }),
    }),
    getApiRolePermissionRolesWithPermissionsByCompanyGroupId: build.query<
      GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiResponse,
      GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-permissions/${queryArg.companyGroupId}`,
      }),
    }),
    postApiRolePermissionAssignUsersToRoleByCompanyGroupId: build.mutation<
      PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiResponse,
      PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign-users-to-role/${queryArg.companyGroupId}`,
        method: "POST",
        body: queryArg.roleAssignmentDto,
      }),
    }),
    getApiRolePermissionRolesWithUsersByCompanyGroupId: build.query<
      GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiResponse,
      GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-users/${queryArg.companyGroupId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiRolePermissionRolesByCompanyGroupIdApiResponse =
  /** status 200 OK */ RoleDto[];
export type GetApiRolePermissionRolesByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type GetApiRolePermissionPermissionsByCompanyGroupIdApiResponse =
  /** status 200 OK */ PermissionDto[];
export type GetApiRolePermissionPermissionsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiRolePermissionAssignApiResponse = unknown;
export type PostApiRolePermissionAssignApiArg = {
  rolePermissionDto: RolePermissionDto;
};
export type GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiResponse =
  /** status 200 OK */ RoleWithPermissionsDto[];
export type GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiResponse =
  unknown;
export type PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiArg = {
  companyGroupId: string;
  roleAssignmentDto: RoleAssignmentDto;
};
export type GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiResponse =
  /** status 200 OK */ RoleWithUsersDto[];
export type GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type RoleDto = {
  id?: string;
  name?: string | null;
};
export type PermissionDto = {
  id?: string;
  name?: string | null;
};
export type RolePermissionDto = {
  roleId?: string;
  permissionIds?: string[] | null;
};
export type RoleWithPermissionsDto = {
  roleId?: string;
  roleName?: string | null;
  permissions?: PermissionDto[] | null;
};
export type FilterDto = {
  action?: string | null;
  variable?: string | null;
  value?: string[] | null;
};
export type RoleAssignmentDto = {
  roleId?: string;
  filters?: FilterDto[] | null;
};
export type RoleWithUsersDto = {
  roleId?: string;
  roleName?: string | null;
  userCount?: number;
  formula?: FilterDto[] | null;
};
export const {
  useGetApiRolePermissionRolesByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionRolesByCompanyGroupIdQuery,
  useGetApiRolePermissionPermissionsByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionPermissionsByCompanyGroupIdQuery,
  usePostApiRolePermissionAssignMutation,
  useGetApiRolePermissionRolesWithPermissionsByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionRolesWithPermissionsByCompanyGroupIdQuery,
  usePostApiRolePermissionAssignUsersToRoleByCompanyGroupIdMutation,
  useGetApiRolePermissionRolesWithUsersByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionRolesWithUsersByCompanyGroupIdQuery,
} = injectedRtkApi;
