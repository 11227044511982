import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Select, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import GoalTableRow from './GoalTableRow';
import GoalTableSummary from './GoalTableSummary';
import TextArea from 'antd/es/input/TextArea';
import { kpiGoalCalculationSource } from '../../data/data';
import { DataItem } from '../../models/DataItem';
import { PerformanceGoalDetailDto, PerformanceGoalDto } from '../../api/services/PerformanceGoal';
import { Guid } from 'typescript-guid';
import moment from 'moment';

const initialRows: PerformanceGoalDetailDto[] = [
  { id: Guid.create().toString(), strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0 },
  { id: Guid.create().toString(), strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0 },
  { id: Guid.create().toString(), strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0 },
];



export interface GoalTableParams {
  goal: PerformanceGoalDto | undefined
}

export interface calculatedFieldModel {
  weight: number
}

const GoalTable: React.FC<GoalTableParams> = ({ goal }) => {
  const [dataSource, setDataSource] = useState<PerformanceGoalDetailDto[]>(initialRows);
  const [calculatedFields, setCalculatedFields] = useState<calculatedFieldModel>({
    weight: 0
  })

  useEffect(() => {
    if (goal) {
      var details = goal?.goalDetails ?? []

      var sorted = [...details]
      sorted.sort(function (left, right) {
        return moment.utc(left.createdAt).diff(moment.utc(right.createdAt))
      })
      
      setDataSource(sorted)
    }
  }, [goal])


  const handleAddRow = () => {
    const newRow: PerformanceGoalDetailDto = {
      id: `${dataSource.length + 1}`,
      strategicGoal: '',
      kpi: '',
      weight: 0,
      source: '',
      minResult: 0,
      targetResult: 0,
      maxResult: 0,
    };
    setDataSource([...dataSource, newRow]);
  };

  const handleChange = (key: string, field: string, value: any) => {
    const newData = dataSource.map((row) => {
      if (row.id === key) {
        return { ...row, [field]: value };
      }
      return row;
    });

    setDataSource(newData);

  };

  useEffect(() => {
    setTotalRow()
  }, [dataSource])


  const calculateTotal = (field: keyof PerformanceGoalDetailDto) => {
    var calculated = dataSource.reduce((sum, row) => sum + (Number(row[field]) || 0), 0);

    return calculated
  };

  const setTotalRow = () => {

    const totalRow = {
      weight: calculateTotal('weight')
    } as calculatedFieldModel;

    setCalculatedFields(totalRow)
  }


  const columns = [
    {
      title: 'Stratejik Hedef',
      dataIndex: 'strategicGoal',
      key: 'strategicGoal',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "strategicGoal"]}
          rules={[
            { required: true, message: "Stratejik Hedef yazmalısınız" }
          ]}
          initialValue={text}
        >
          <TextArea
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'strategicGoal', e.target.value)}
            style={{ height: '40px', width: '200px', resize: 'none' }} // TextArea için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'KPI',
      dataIndex: 'kpi',
      key: 'kpi',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "kpi"]}
          rules={[
            { required: true, message: "KPI yazmalısınız" }
          ]}
          initialValue={text}
        >
          <TextArea
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'kpi', e.target.value)}
            style={{ height: '40px', width: '200px', resize: 'none' }} // TextArea için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'Hedef Ağırlık %',
      dataIndex: 'weight',
      key: 'weight',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "weight"]}
          rules={[
            { required: true, message: "Hedef Ağırlık yazmalısınız" }
          ]}
          initialValue={text}
        >
          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'weight', parseFloat(e.target.value))}
            suffix="%"
            style={{ height: '40px', width: '80px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'Hedef Ölçüm Kaynağı',
      dataIndex: 'source',
      key: 'source',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "source"]}
          rules={[
            { required: true, message: "Hedef Ölçüm Kaynağı seçmelisiniz" }
          ]}
          initialValue={text}
        >

          <Select
            value={text}
            onChange={(value) => handleChange(record.id ?? "", 'source', value)}
            style={{ height: '40px', width: '250px' }} // Select için yükseklik ayarı
          >
            {kpiGoalCalculationSource.map((item: DataItem) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      ),
    },
    {
      title: 'Min Hedef Sonuç',
      dataIndex: 'minResult',
      key: 'minResult',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "minResult"]}
          rules={[
            { required: true, message: "Min Hedef Sonuç yazmalısınız" }
          ]}
          initialValue={text}
        >

          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'minResult', parseFloat(e.target.value))}
            style={{ height: '40px', width: '80px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      title: 'Hedef Sonuç',
      dataIndex: 'targetResult',
      key: 'targetResult',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "targetResult"]}
          rules={[
            { required: true, message: "Hedef Sonuç yazmalısınız" }
          ]}
          initialValue={text}
        >
          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'targetResult', parseFloat(e.target.value))}
            style={{ height: '40px', width: '80px' }} // Input için yükseklik ayarı
          />
        </Form.Item>
      ),
    },
    {
      title: 'Max Hedef Sonuç',
      dataIndex: 'maxResult',
      key: 'maxResult',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "maxResult"]}
          rules={[
            { required: true, message: "Max Hedef Sonuç yazmalısınız" }
          ]}
          initialValue={text}
        >
          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'maxResult', parseFloat(e.target.value))}
            style={{ height: '40px', width: '80px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
  ];


  return (
    <>
      <Button onClick={handleAddRow} type="primary" icon={<PlusOutlined />} style={{ marginBottom: 16 }}>
        Satır Ekle
      </Button>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        rowKey="key"
        summary={() => <GoalTableSummary total={calculatedFields} />}
      />
    </>
  );
};

export default GoalTable;
