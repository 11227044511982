import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Table} from 'antd';
import StrategicOKREntryModal from './StrategicOKREntryModal';

import {t} from 'i18next';
import {useSelector, useDispatch} from 'react-redux';
import {setLoading, setError} from '../../features/app/appSlice';
import {RootState} from '../../app/store';
import {
    PerformanceGoalDto,
    useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery
} from '../../api/services/PerformanceGoal';
import moment from 'moment';
import {
    StrategicOkrMasterDto, useDeleteApiStrategicOkrDeleteStrategicOkrByIdMutation,
    useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery
} from '../../api/services/StrategicOKR';
import {Guid} from 'typescript-guid';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

const {confirm} = Modal;


const StrategicOKREntryPage: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [getStrategicOKRs, {data: strategicOKRs}] = useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery()
    const [deleteStrategicOKR] = useDeleteApiStrategicOkrDeleteStrategicOkrByIdMutation();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [selectedRow, setselectedRow] = useState<StrategicOkrMasterDto>()
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Performans Dönemi', dataIndex: 'period', key: 'period', render: (data: any) => {
                return `${data.periodName} (${moment(data.startDate).format("DD/MM/YYYY")} - ${moment(data.startDate).format("DD/MM/YYYY")})`
            }
        },
        {
            title: 'Performans Sistemi',
            dataIndex: 'performanceSystem',
            key: 'performanceSystem',
            render: (data: any) => {
                return `${data.systemName}`
            }
        },
        {
            title: '', dataIndex: 'deletable', key: 'deletable',
            render: (data: any, rec: any) => (
                rec.deletable ?
                    (<Button onClick={(e) => {
                        e.stopPropagation()
                        showDeleteConfirm(rec.id)

                    }}>
                        <DeleteOutlined/>
                    </Button>) : null
            )
        },
    ];


    useEffect(() => {
        if (user) {
            fetchPerformanceGoals()
        }
    }, [user])

    const showDeleteConfirm = (id: string) => {
        confirm({
            title: "Bu kaydı silmek istediğinize emin misiniz?",
            icon: <ExclamationCircleOutlined/>,
            content: "Silindikten sonra geri alamazsınız.",
            okText: t('yes'),
            okType: 'danger',
            cancelText: t('no'),
            onOk() {
                handleDelete(id);
            },
        });
    };

    const handleDelete = async (id: string) => {
        await deleteOKR(id);
        await fetchPerformanceGoals();
    }

    const deleteOKR = async (id: string) => {
        try {
            dispatch(setLoading(true));
            await deleteStrategicOKR({id: id}).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans hedefi silinirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const fetchPerformanceGoals = async () => {
        try {
            dispatch(setLoading(true));
            await getStrategicOKRs({
                companyGroupId: user?.company?.companyGroupId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans hedefleri çekilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const onModalClose = async () => {
        setModalVisible(false)
        form.resetFields()
        setselectedRow(undefined)
    }

    const onRowClick = (values: any) => {
        setselectedRow(values)

    }

    useEffect(() => {
        if (selectedRow != undefined) {
            setModalVisible(true)
        }
    }, [selectedRow])


    const addNewClicked = () => {
        form.resetFields()
        setselectedRow(undefined)
        setModalVisible(true)
    }


    return (
        <>
            <Button type="primary" onClick={() => addNewClicked()} style={{marginBottom: 16}}>
                Stratejik OKR Ekle
            </Button>
            <Table dataSource={strategicOKRs}
                   columns={columns}
                   pagination={false}
                   onRow={(record) => ({
                       onClick: () => onRowClick(record),
                   })}/>
            <StrategicOKREntryModal
                form={form}
                visible={modalVisible}
                onClose={() => onModalClose()}
                onChange={async () => {
                    await fetchPerformanceGoals()
                }}
                initialValues={
                    {
                        ...selectedRow,
                        periodId: selectedRow?.period?.id ?? undefined,
                        systemdId: selectedRow?.performanceSystem?.id ?? undefined,
                        rows: (selectedRow?.okrDetails ?? []).map(k => ({
                            id: Guid.create().toString(),
                            okr: k.strategicOKR,
                            weight: k.okrWeight,
                            goalMeasurementSource: k.targetSource,
                            targetResult: k.targetResult
                        })),
                    }
                }/>
        </>
    );
};

export default StrategicOKREntryPage;
