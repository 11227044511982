import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './app/i18n';
import reportWebVitals from './reportWebVitals';
import i18n from './app/i18n';

import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18n.on('initialized', function (options) {
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
