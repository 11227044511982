import React, { useEffect, useState } from 'react';
import { Table, Typography, Divider, Form, Input } from 'antd';
import './ScaleTables.css';
import { EvaluationType, ScaleDetailDto, ScaleDto, ScaleType } from '../../api/services/scale';
import RowComponent from './RowComponent';
import { Guid } from 'typescript-guid';

const { Title } = Typography;

interface ScaleTablesProps {
  scaleType: ScaleType | null;
  scaleData: ScaleDto | null;
}

const ScaleTables: React.FC<ScaleTablesProps> = ({ scaleType, scaleData }) => {
  // if (!scaleType) return null;

  const [rowCount, setRowCount] = useState(scaleType === 1 ? 3 : scaleType === 2 ? 4 : 5)
  const [data, setData] = useState<ScaleDetailDto[]>([])
  const [totalQuotaFirst, setTotalQuotaFirst] = useState(0)
  const [totalQuotaSecond, setTotalQuotaSecond] = useState(0)

  useEffect(() => {
    if (scaleType) {
      setRowCount(scaleType === 1 ? 3 : scaleType === 2 ? 4 : 5)
    }
  }, [scaleType])

  useEffect(() => {
    if (scaleData && scaleData.scaleDetails && scaleData.scaleDetails.length > 0) {
      setData(scaleData.scaleDetails)
    } else {
      var first = Array.from({ length: rowCount }, (_, i) => ({
        key: Guid.create().toString(),
        result: '',
        minScore: 0,
        maxScore: 0,
        resultDescription: '',
        quotaResult: 0,
        isQuotaChecked: false,
        evaluationType: 1
      } as ScaleDetailDto))

      var second = Array.from({ length: rowCount }, (_, i) => ({
        key: Guid.create().toString(),
        result: '',
        minScore: 0,
        maxScore: 0,
        resultDescription: '',
        quotaResult: 0,
        isQuotaChecked: false,
        evaluationType: 2
      } as ScaleDetailDto))

      setData([...first, ...second])
    }

    calculateFirstTotalQuota()
    calculateSecondTotalQuota()
  }, [scaleData, rowCount])

  useEffect(() => {
    calculateFirstTotalQuota()
    calculateSecondTotalQuota()
  }, [data])

  const handleRowChange = (key: string, updatedRow: ScaleDetailDto) => {
    const updatedData = data.map(item => (item.id === key.toString() ? updatedRow : item));
    setData(updatedData);
  };

  const calculateFirstTotalQuota = () => {
    var sum = data.filter(k => k.evaluationType == 1)
      .reduce((n, newVal, index) => (n + Number(newVal.quotaResult ?? 0)), 0)
    setTotalQuotaFirst(sum)
  }

  const calculateSecondTotalQuota = () => {
    var sum = data.filter(k => k.evaluationType == 2)
    .reduce((n, newVal, index) => (n + Number(newVal.quotaResult ?? 0)), 0)
    setTotalQuotaSecond(sum)
  }

  const renderTable = (title: string, type: EvaluationType) => {

    return (
      <>
        <Divider orientation="left">
          <Title level={5} style={{ marginBottom: 0 }}>{title}</Title>
        </Divider>
        <table>
          <thead>
            <tr>
              <th style={{ width: 200 }}>Sonuç</th>
              <th style={{ width: 200 }}>Alt Puan</th>
              <th style={{ width: 200 }}>Üst Puan</th>
              <th style={{ width: 300 }}>Sonuç Açıklama</th>
              <th style={{ width: 200 }}>Sonuç Kota</th>
              <th style={{ width: 200 }}>Kota Uygunluğu</th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.filter(k => k.evaluationType == type)
              .sort((k, l) => (l.maxScore ?? 0) - (k.maxScore ?? 0))
              .map((row, index) => (
                <RowComponent key={row.id} index={index} row={row} onRowChange={handleRowChange} />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ width: 200 }}></th>
              <th style={{ width: 200 }}></th>
              <th style={{ width: 200 }}></th>
              <th style={{ width: 300 }}></th>
              <th style={{ width: 200 }}><Input disabled style={{ height: 40, margin: 5 }} suffix="%" value={type == 1 ? totalQuotaFirst : totalQuotaSecond} /></th>
              <th style={{ width: 200 }}></th>
            </tr>
          </tfoot>
        </table>
      </>
    )
  }

  return (
    <>
      {renderTable('Ne Yaptık? (Hedefler)', 1)}
      {renderTable('Nasıl Yaptık? (Yetkinlikler)', 2)}
    </>
  );
};

export default ScaleTables;
