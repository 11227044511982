import React from 'react';
import { Layout, Menu, Avatar, Dropdown, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { useTranslation } from 'react-i18next';
import { logout } from '../app/persistedSlice';
import { useHistory } from 'react-router-dom';

const { Header } = Layout;
const { Option } = Select;

const AppHeader: React.FC = () => {
    const { t, i18n } = useTranslation();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const history = useHistory();

    const logoutClicked = () => {
        dispatch(logout())
        history.push('/');
    }

    const profileClicked = () => {
        history.push('/profile');
    }

    const menu = (
        <Menu>
            <Menu.Item key="profile" onClick={profileClicked}>{t('profile')}</Menu.Item>
            <Menu.Item key="logout" onClick={logoutClicked}>{t('logout')}</Menu.Item>
            <Menu.SubMenu key="language" title={t('language')}>
                <Menu.Item key="en" onClick={() => i18n.changeLanguage('en')}>English</Menu.Item>
                <Menu.Item key="tr" onClick={() => i18n.changeLanguage('tr')}>Türkçe</Menu.Item>
            </Menu.SubMenu>
        </Menu>
    );

    const handleChangeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    return (
        <Header className="header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="logo" style={{ color: 'white', fontSize: '20px' }}>
                {t('welcome')}
            </div>
            <Dropdown overlay={menu} placement="bottomRight">
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Avatar icon={<UserOutlined />} />
                    <span style={{ color: 'white', marginLeft: '8px' }}>{user?.firstName} {user?.lastName}</span>
                </div>
            </Dropdown>
        </Header>
    );
};

export default AppHeader;
