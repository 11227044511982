import React, { useEffect, useState } from 'react';
import { Table, Input, Divider, Form } from 'antd';
import { findNameById, findNameByRangeId } from './helpers';
import { kpiActions, kpiTypes, okrActions, okrTypes } from '../../data/data';
import { PerformanceSystemType, PerformanceSystemTypeIndex } from '../../enums/PerformanceSystemType';
import { PerformanceSystemDto, PerformanceSystemRangeDto } from '../../api/services/performanceSystem';
import { useLazyGetApiParameterFilteredParametersQuery } from '../../api/services/parameters';
import { DataItem } from '../../models/DataItem';

interface PerformanceSystemTablesProps {
  showOkrWeightTable: boolean;
  okrWeightData?: PerformanceSystemRangeDto[];
  okrActionData?: PerformanceSystemRangeDto[];
  kpiWeightData?: PerformanceSystemRangeDto[];
  kpiActionData?: PerformanceSystemRangeDto[];
  userRoles?: DataItem[];
  initialValues?: PerformanceSystemDto | null;
  parameters: any[];
  systemType: PerformanceSystemTypeIndex | null;
}

const PerformanceSystemTables: React.FC<PerformanceSystemTablesProps> =
  ({ showOkrWeightTable, okrWeightData, okrActionData, initialValues, kpiWeightData, kpiActionData, parameters, userRoles, systemType }) => {

    const [kpiGoalCompentencyDetailsData, setKpiGoalCompentencyDetailsData] = useState<any>()

    const okrWeightColumns = [
      { title: '', dataIndex: 'rangeType', key: 'okrType', render: (text: string) => findNameByRangeId(Number(text), okrTypes) },
      {
        title: 'Min %', dataIndex: 'minValue', key: 'minValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`weight`, rec.rangeType, "minValue"]}
            dependencies={[`weight.${rec.rangeType}.maxValue`]}
            rules={[
              { required: true, message: "Min OKR ağırlığı yazmalısınız" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('En fazla 100 olabilir'));
                },
              }),
              // ({ getFieldValue }) => ({
              //   validator(_, value) {
              //     const fieldBValue = getFieldValue(`weight.${rec.rangeType}.maxValue`) || 0; //weight_7_maxValue
              //     debugger
              //     if ((value || 0) + fieldBValue === 100) {
              //       return Promise.resolve();
              //     }
              //     return Promise.reject(new Error('Max ve Min toplamı 100 olmalıdır'));
              //   },
              // }),
            ]}
            initialValue={text}
          >
            < Input type="number" min={0} max={100} />
          </Form.Item>
        )
        // <Input type="number" min={0} max={100} value={text} /> 
      },
      {
        title: 'Max %', dataIndex: 'maxValue', key: 'maxValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`weight`, rec.rangeType, "maxValue"]}
            dependencies={[`weight.${rec.rangeType}.minValue`]}
            rules={[
              { required: true, message: "Max OKR ağırlığı yazmalısınız" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value <= 100) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('En fazla 100 olabilir'));
                },
              }),
              // ({ getFieldValue }) => ({
              //   validator(_, value) {
              //     const fieldBValue = getFieldValue(`weight.${rec.rangeType}.minValue`) || 0; //weight_7_maxValue
              //     debugger
              //     if ((value || 0) + fieldBValue === 100) {
              //       return Promise.resolve();
              //     }
              //     return Promise.reject(new Error('Max ve Min toplamı 100 olmalıdır'));
              //   },
              // }),
            ]}
            initialValue={text}
          >
            < Input type="number" min={0} max={100} />
          </Form.Item>
        )
        //  <Input type="number" min={0} max={100} value={text} />
      },
    ];

    const okrActionColumns = [
      { title: '', dataIndex: 'rangeType', key: 'okrAction', render: (text: string) => findNameByRangeId(Number(text), okrActions) },
      {
        title: 'Min', dataIndex: 'minValue', key: 'minValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`action`, rec.rangeType, "minValue"]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" />
          </Form.Item>
        )
        //  <Input type="number" value={text} /> 
      },
      {
        title: 'Max', dataIndex: 'maxValue', key: 'maxValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`action`, rec.rangeType, "maxValue"]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" />
          </Form.Item>
        )
        //  <Input type="number" value={text} /> 
      },
    ];

    const kpiWeightColumns = [
      { title: '', dataIndex: 'rangeType', key: 'kpiType', render: (text: string) => findNameByRangeId(Number(text), kpiTypes) },
      {
        title: 'Min %', dataIndex: 'minValue', key: 'minValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`weight`, rec.rangeType, "minValue"]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" min={0} max={100} />
          </Form.Item>
        )

        // <Input type="number" min={0} max={100} value={text} suffix="%" /> 
      },
      {
        title: 'Max %', dataIndex: 'maxValue', key: 'maxValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`weight`, rec.rangeType, "maxValue"]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" min={0} max={100} />
          </Form.Item>
        )
        // <Input type="number" min={0} max={100} value={text} suffix="%" />
      },
    ];

    const kpiActionColumns = [
      { title: '', dataIndex: 'rangeType', key: 'kpiAction', render: (text: string) => findNameByRangeId(Number(text), kpiActions) },
      {
        title: 'Min', dataIndex: 'minValue', key: 'minValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`action`, rec.rangeType, "minValue"]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            <Input type="number" />
          </Form.Item>
        ),
      },
      //  <Input type="number" value={text} /> },
      {
        title: 'Max', dataIndex: 'maxValue', key: 'maxValue', render: (text: number, rec: any) => (
          <Form.Item
            name={[`action`, rec.rangeType, "maxValue"]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            <Input type="number" />
          </Form.Item>
        ),
        //  < Input type = "number" value = { text } /> 
      },
    ];

    const columnsForRatio = [
      {
        title: '',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Hedef %',
        dataIndex: 'goalPercentage',
        key: 'goalPercentage',
        render: (text: string, rec: any) => (
          <Form.Item
            name={["goal_compentency", `goalPercentage`, rec.roleId]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" />
          </Form.Item>
        )
      },
      {
        title: 'Yetkinlik %',
        dataIndex: 'competencyPercentage',
        key: 'competencyPercentage',
        render: (text: string, rec: any) => (
          <Form.Item
            name={["goal_compentency", `competencyPercentage`, rec.roleId]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" />
          </Form.Item>
        )
      },
    ];

    const columnsForGoals = [
      {
        title: '',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'KURUM HEDEFLERİ',
        dataIndex: 'corporateGoals',
        key: 'corporateGoals',
        render: (text: string, rec: any) => (
          <Form.Item
            name={["goals_sub", `corporateGoals`, rec.roleId]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" />
          </Form.Item>
        )
      },
      {
        title: 'DEPARTMAN HEDEFLERİ',
        dataIndex: 'departmentGoals',
        key: 'departmentGoals',
        render: (text: string, rec: any) => (
          <Form.Item
            name={["goals_sub", `departmentGoals`, rec.roleId]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" />
          </Form.Item>
        )
      },
      {
        title: 'BİREYSEL HEDEFLER',
        dataIndex: 'individualGoals',
        key: 'individualGoals',
        render: (text: string, rec: any) => (
          <Form.Item
            name={["goals_sub", `individualGoals`, rec.roleId]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" />
          </Form.Item>
        )
      },
      {
        title: 'Hedef Toplam %',
        dataIndex: 'totalGoals',
        key: 'totalGoals',
        render: (text: any, record: any) => {
          const total = (parseFloat(record.corporateGoals || 0) +
            parseFloat(record.departmentGoals || 0) +
            parseFloat(record.individualGoals || 0));

          return (
            <Form.Item
              name={["goals_sub", `totalGoals`]}
              // rules={[
              //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
              // ]}
              initialValue={total}
            >
              <Input type="number" suffix="%" disabled />
            </Form.Item>
          )
        },
      },
    ];

    const columnsForCompetencies = [
      {
        title: '',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'YÖNETSEL YETKİNLİKLER',
        dataIndex: 'managerialCompetencies',
        key: 'managerialCompetencies',
        render: (text: string, rec: any) => (
          <Form.Item
            name={["compentency_sub", `managerialCompetencies`, rec.roleId]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" />
          </Form.Item>
        )
      },
      {
        title: 'TEMEL YETKİNLİKLER',
        dataIndex: 'coreCompetencies',
        key: 'coreCompetencies',
        render: (text: string, rec: any) => (
          <Form.Item
            name={["compentency_sub", `coreCompetencies`, rec.roleId]}
            // rules={[
            //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
            // ]}
            initialValue={text}
          >
            < Input type="number" suffix="%" />
          </Form.Item>
        )
      },
      {
        title: 'Yetkinlik Toplam %',
        dataIndex: 'totalCompetencies',
        key: 'totalCompetencies',
        render: (text: any, record: any) => {
          const total = (parseFloat(record.managerialCompetencies || 0) +
            parseFloat(record.coreCompetencies || 0));
          return (
            <Form.Item
              name={["compentency_sub", `totalCompentency`]}
              // rules={[
              //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
              // ]}
              initialValue={total}
            >
              <Input type="number" suffix="%" disabled />
            </Form.Item>
          )
        },
      },
    ];

    const calculateKpiGoalCompentencyDetailsData = () => {
      var ds: any[] = []

      userRoles?.forEach(k => {
        var roleDetails = initialValues?.kpiGoalCompentencyDetails?.find((l: any) => l.role == k.id ?? "")
        var d = {
          key: k.rangeType,
          role: k.name,
          roleId: k.id,
          goalPercentage: roleDetails?.goalPercentage ?? 0,
          competencyPercentage: roleDetails?.competencyPercentage ?? 0,
          corporateGoals: roleDetails?.corporateGoals ?? 0,
          departmentGoals: roleDetails?.departmentGoals ?? 0,
          individualGoals: roleDetails?.individualGoals ?? 0,
          managerialCompetencies: roleDetails?.managerialCompetencies ?? 0,
          coreCompetencies: roleDetails?.coreCompetencies ?? 0,
        }

        ds.push(d)

      })

      setKpiGoalCompentencyDetailsData(ds)
    }
    useEffect(() => {
      calculateKpiGoalCompentencyDetailsData()
    }, [initialValues, userRoles])

    return (
      <>
        {(systemType == 1 || initialValues?.performanceSystemType === PerformanceSystemTypeIndex.OKR) &&
          <>
            {showOkrWeightTable && (
              <>
                <Divider orientation="left">OKR Ağırlıkları</Divider>
                <Table columns={okrWeightColumns} dataSource={okrWeightData} pagination={false} rowKey="okrType" />
              </>
            )}
            <>
              <Divider orientation="left">OKR/KR/Aksiyon Sayısı</Divider>
              <Table columns={okrActionColumns} dataSource={okrActionData} pagination={false} rowKey="okrAction" />
            </>
          </>
        }
        {(systemType == 2 || initialValues?.performanceSystemType === PerformanceSystemTypeIndex.KPI) &&
          <>
            <Divider orientation="left">Hedef / Yetkinlik Oranları</Divider>
            <Table columns={columnsForRatio} dataSource={kpiGoalCompentencyDetailsData} pagination={false} bordered />

            <Divider orientation="left">Hedef %</Divider>
            <Table columns={columnsForGoals} dataSource={kpiGoalCompentencyDetailsData} pagination={false} bordered />

            <Divider orientation="left">Yetkinlik %</Divider>
            <Table columns={columnsForCompetencies} dataSource={kpiGoalCompentencyDetailsData} pagination={false} bordered />

            <Divider orientation="left">Hedef Ağırlıkları</Divider>
            <Table columns={kpiWeightColumns} dataSource={kpiWeightData} pagination={false} rowKey="okrType" />

            <Divider orientation="left">Hedef Sayısı</Divider>
            <Table columns={kpiActionColumns} dataSource={kpiActionData} pagination={false} rowKey="okrAction" />
          </>
        }
      </>
    );
  };

export default PerformanceSystemTables;
