import React from 'react';
import { Card, Collapse, Table, Button, Divider } from 'antd';
import { PerformanceCardReviewDto } from '../../../api/services/performanceCards';
import moment from 'moment';
import Title from 'antd/es/typography/Title';
import PerformanceSystemDetails from './PerformanceSystemDetails';

const { Panel } = Collapse;

interface PerformanceSummaryProps {
  reviewData: PerformanceCardReviewDto | null;
  onNext: () => void;
  onPrevious: () => void;
}

const PerformanceSummaryStep: React.FC<PerformanceSummaryProps> = ({ onNext, onPrevious, reviewData }) => {
  const targetAudienceColumns = [
    { title: 'Sicil Numarası', dataIndex: 'employeeNumber', key: 'employeeNumber' },
    { title: 'Adı', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Soyadı', dataIndex: 'lastName', key: 'lastName' },
  ];

  const scaleColumns = [
    { title: 'Sonuç', dataIndex: 'result', key: 'result' },
    { title: 'Alt Puan', dataIndex: 'minScore', key: 'minScore' },
    { title: 'Üst Puan', dataIndex: 'maxScore', key: 'maxScore' },
    { title: 'Sonuç Kota', dataIndex: 'quotaResult', key: 'quotaResult' },
    { title: 'Kota Uygunluğu', dataIndex: 'isQuotaChecked', key: 'isQuotaChecked', render: (text: any) => <a>{text ? "Evet" : "Hayır"}</a>, },
  ];

  return (
    <div>
      {/* Kart Adı */}
      <Card title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: "normal", fontSize: 14 }}>Kart Adı</span>
        </div>
      }
        style={{ marginBottom: 16 }}>
        {reviewData?.cardName ?? ""}
      </Card>

      {/* Performans Dönemi */}
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: "normal", fontSize: 14 }}>Performans Dönemi</span>
            <span style={{ fontWeight: "normal", fontSize: 14 }}>{reviewData?.periodName?.periodName ?? ""}</span>
          </div>
        }

        style={{ marginBottom: 16 }}
      >
        Başlangıç Tarihi: {moment(reviewData?.periodName?.startDate).format("DD.MM.YYYY")} <br />
        Bitiş Tarihi: {moment(reviewData?.periodName?.endDate).format("DD.MM.YYYY")}
      </Card>

      {/* Performans Sistemi */}
      <Collapse style={{ marginBottom: 16 }}>
        <Panel
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Performans Sistemi</span>
              <span> {reviewData?.performanceSystemName?.systemName ?? ""} </span>
            </div>
          }
          key="1"
        >
          <PerformanceSystemDetails reviewData={reviewData} />
        </Panel>
      </Collapse>

      {/* Hedef Kitle */}
      <Collapse style={{ marginBottom: 16 }}>
        <Panel
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Hedef Kitle</span>
              <span>{(reviewData?.targetAudienceList ?? []).length} kişi</span>
            </div>
          }
          key="1"
        >
          <Table
            dataSource={reviewData?.targetAudienceList ?? []}
            columns={targetAudienceColumns}
            pagination={false}
            scroll={{ y: 400 }} // Yüksekliği sabit tablolar için scroll ekle
            rowKey="id"
          />
        </Panel>
      </Collapse>

      {/* Skala Tipi */}
      <Collapse>
        <Panel
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Skala Tipi</span>
              <span><span style={{ fontWeight: "bolder" }}>{reviewData?.scaleTypeName?.scaleType ? "OKR" : "KPI"}</span> ({reviewData?.scaleTypeName?.scaleName ?? ""})</span>
            </div>
          }
          key="1"
        >
          <Divider orientation="left">
            <Title level={5} style={{ marginBottom: 0 }}>Ne Yaptık? (Hedefler)</Title>
          </Divider>
          <Table
            dataSource={reviewData?.scaleTypeName?.scaleDetails?.filter(k => k.evaluationType == 1) ?? []}
            columns={scaleColumns}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}><span style={{ fontWeight: "bolder" }}>Sonuç Açıklama:</span> {record.resultDescription ?? ""}</p>,
              rowExpandable: (record) => true,
            }}
            scroll={{ y: 400 }} // Yüksekliği sabit tablolar için scroll ekle
            rowKey="id"
          />
          <Divider orientation="left" className='mt-2'>
            <Title level={5} style={{ marginBottom: 0 }}>Nasıl Yaptık? (Yetkinlikler)</Title>
          </Divider>
          <Table
            dataSource={reviewData?.scaleTypeName?.scaleDetails?.filter(k => k.evaluationType == 2) ?? []}
            columns={scaleColumns}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}><span style={{ fontWeight: "bolder" }}>Sonuç Açıklama:</span> {record.resultDescription ?? ""}</p>,
              rowExpandable: (record) => true,
            }}
            scroll={{ y: 400 }} // Yüksekliği sabit tablolar için scroll ekle
            rowKey="id"
          />
        </Panel>
      </Collapse>

      {/* Geri ve Devam Butonları */}
      <div style={{ textAlign: 'right', marginTop: 24 }}>
        <Button onClick={onPrevious} style={{ marginRight: 8 }}>
          Geri
        </Button>
        <Button type="primary" onClick={onNext}>
          Devam
        </Button>
      </div>
    </div >
  );
};

export default PerformanceSummaryStep;
