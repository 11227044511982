import { DataItem } from "../models/DataItem";

// data.ts


export const periods: DataItem[] = [
  { id: '550e8400-e29b-41d4-a716-446655440000', name: '2024-Q1' },
  { id: '550e8400-e29b-41d4-a716-446655440001', name: '2024-Q2' },
  { id: '550e8400-e29b-41d4-a716-446655440002', name: '2024-Q3' },
  { id: '550e8400-e29b-41d4-a716-446655440003', name: '2024-Q4' },
];


export const okrActions: DataItem[] = [
  { rangeType: 1, name: 'OKR Sayısı' },
  { rangeType: 2, name: 'KR Sayısı' },
  { rangeType: 3, name: 'OKR/KR Başına Aksiyon Sayısı' },
];

export const okrTypes: DataItem[] = [
  { rangeType: 4, name: 'Stratejik OKR' },
  { rangeType: 5, name: 'Takım OKR' },
  { rangeType: 6, name: 'Bireysel OKR' },
  { rangeType: 7, name: 'Gelişim OKR' },
];

export const kpiGoalCalculationSource: DataItem[] = [
  { id: '75e3a07b-df63-49ad-8e80-1d4b095cfa8a', name: 'Finansal Raporlama' },
  { id: 'a2b3fda3-1e0a-4c98-bb39-b3ae1c5e1f69', name: 'İnsan Kaynakları Raporlaması' },
  { id: 'f8c8f6f9-b43a-46f7-8717-58a6b9de6a65', name: 'Operasyonel Raporlamalar' },
  { id: 'f8c8f6f9-b43a-46f7-8717-58a6b9de6a66', name: 'Satış Raporlamaları' },
  { id: 'f8c8f6f9-b43a-46f7-8717-58a6b9de6a67', name: 'Yönetici Değerlendirme' },
];

export const kpiActions: DataItem[] = [
  { rangeType: 8, name: 'Stratejik Hedef' },
  { rangeType: 9, name: 'Takım Hedefi' },
  { rangeType: 10, name: 'Bireysel Hedef' },
  { rangeType: 11, name: 'Gelişim Hedefi' },
];

export const kpiTypes: DataItem[] = [
  { rangeType: 12, name: 'Stratejik Hedef' },
  { rangeType: 13, name: 'Takım Hedefi' },
  { rangeType: 14, name: 'Bireysel Hedef' },
  { rangeType: 15, name: 'Gelişim Hedefi' },
];
