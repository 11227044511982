export enum ScaleType {
    THREE_POINT = "3'lü Skala",
    FOUR_POINT = "4'lü Skala",
    FIVE_POINT = "5'li Skala",
  }
  
  export enum ScaleTypeNames {
    "_",
    "3'lü Skala",
    "4'lü Skala",
    "5'li Skala",
  }
  