import React from 'react';
import { Table, Button, Modal } from 'antd';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UserDto } from '../../api/services/user';
import { t } from 'i18next';
import { CompanyDto } from '../../api/services/company';

const { confirm } = Modal;

interface CompanyTableProps {
    companies: CompanyDto[];
    handleEdit: (company: CompanyDto) => void;
}

const CompanyTable: React.FC<CompanyTableProps> = ({ companies, handleEdit }) => {

    const columns = [
        { title: t('name'), dataIndex: 'name', key: 'name' },
        { title: t('adres'), dataIndex: 'address', key: 'address' },
        { title: t('email'), dataIndex: 'email', key: 'email' },
    ];

    return (
        <Table
            dataSource={companies}
            columns={columns}
            rowKey="id"
            onRow={(record) => {
                return {
                    onClick: () => handleEdit(record)
                };
            }}
        />
    );
};

export default CompanyTable;
