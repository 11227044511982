
import React, { useEffect } from 'react';
import { Form, Input, DatePicker, Select, Button, message, Modal } from 'antd';
import dayjs from 'dayjs';
import card from 'antd/es/card';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { usePostApiPerformanceCardsAddOrUpdateUserKrMutation, usePostApiPerformanceCardsAddUserOkrMutation, UserKrDetailsDto, UserKrdto } from '../../../../api/services/performanceCards';
import { setLoading, setError } from '../../../../features/app/appSlice';
import { RootState } from '../../../../app/store';

const { TextArea } = Input;
const { Option } = Select;


export interface IKRInputModal {
    isActive: boolean
    OKRId: string
    setActive: (status: boolean) => void
    initialValue: UserKrDetailsDto | null
    newOKRAdded: (okr: UserKrdto) => void
    okrUpdated: (okr: UserKrdto) => void
}

export interface KRFormModel {
    krDescription: string;
    targetCompletionDate: Date;
    isPrivate: boolean;
}

const KRInputModal: React.FC<IKRInputModal> = ({ isActive, setActive, OKRId, initialValue, newOKRAdded, okrUpdated }) => {
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [AddKROnService] = usePostApiPerformanceCardsAddOrUpdateUserKrMutation()

    const AddKR = async (values: any) => {
        try {
            dispatch(setLoading(true));

            var payload: UserKrdto = {
                okrId: OKRId,
                id: initialValue != null ? initialValue.krId : null,
                description: values.krDescription ?? "",
                isPrivate: values.isPrivate ?? "",
                targetCompletionDate: values.targetCompletionDate,
            }

            await AddKROnService({
                userKrdto: payload
            }).unwrap()

            if (initialValue == null) {
                newOKRAdded(payload)
            } else {
                okrUpdated(payload)
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('OKR eklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleSave = (values: any) => {
        console.log('Form Values:', values);
        AddKR(values)
    };

    const handleCancel = () => {
        form.resetFields();
        setActive(false)
    };

    // Varsayılan değerler
    const initialValues = {
        krDescription: initialValue != null ? initialValue.description : '',
        targetCompletionDate: initialValue != null ? dayjs(initialValue.targetCompletionDate) : dayjs().add(1, 'week'), // 1 hafta sonrası
        isPrivate: initialValue != null ? initialValue.isPrivate : false,
    };

    return (
        <>
            <Modal
                title={"Yeni KR Ekleme"}
                visible={isActive}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={700}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={handleSave}
                    style={{ maxWidth: 600, margin: 'auto' }}
                >
                    {/* KR Açıklaması */}
                    <Form.Item
                        name="krDescription"
                        label="KR Açıklaması"
                        rules={[{ required: true, message: 'KR açıklaması girilmelidir!' }]}
                    >
                        <TextArea rows={4} placeholder="KR açıklamasını girin" maxLength={500} />
                    </Form.Item>

                    {/* Hedef Tamamlama Zamanı */}
                    <Form.Item
                        name="targetCompletionDate"
                        label="Hedef Tamamlama Zamanı"
                        rules={[{ required: true, message: 'Tamamlama zamanı seçilmelidir!' }]}
                    >
                        <DatePicker
                            placeholder="Tarih seçin"
                            disabledDate={(date) => date.isBefore(dayjs()) || date.isAfter(dayjs().add(3, 'month'))}
                        />
                    </Form.Item>

                    {/* Gizli KR mı? */}
                    <Form.Item
                        name="isPrivate"
                        label="Gizli KR mı?"
                        rules={[{ required: true, message: 'Bu alan seçilmelidir!' }]}
                    >
                        <Select placeholder="Gizlilik durumu seçin">
                            <Option value={true}>Evet</Option>
                            <Option value={false}>Hayır</Option>
                        </Select>
                    </Form.Item>

                    {/* Kaydet ve İptal Butonları */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                            İptal
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Kaydet
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
}


export default KRInputModal;
