import React, { useEffect, useState } from 'react';
import { Table, Button, Drawer, Form, Input, DatePicker, Space, Badge } from 'antd';
import moment from 'moment';
import { t } from 'i18next';
import { PeriodDto, useLazyGetApiPeriodAllQuery, usePostApiPeriodSaveMutation } from '../../api/services/period';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setError } from '../../features/app/appSlice';
import { RootState } from '../../app/store';
import dayjs from "dayjs";

const { Column } = Table;

interface Period {
    id: number;
    number: string;
    name: string;
    startDate: string;
    endDate: string;
}

const PeriodDefinition: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState<PeriodDto | null>(null);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);

    const [fetchPeriods, { data: periods }] = useLazyGetApiPeriodAllQuery()
    const [savePeriod] = usePostApiPeriodSaveMutation()

    useEffect(() => {
        fetchPeriodDatas()
    }, [user])

    const fetchPeriodDatas = async () => {
        try {
            dispatch(setLoading(true));

            await fetchPeriods().unwrap()
        } catch (err) {
            dispatch(setError(t('Dönemler yüklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const SavePeriodDatas = async (data: any) => {
        try {
            dispatch(setLoading(true));

            await savePeriod({
                periodDto: {
                    periodName: data.name,
                    periodNo: data.number,
                    endDate: data.endDate,
                    startDate: data.startDate,
                    id: selectedPeriod?.id,
                    companyGroupId: user?.company?.companyGroupId ?? ""
                }
            }
            ).unwrap()

            fetchPeriodDatas()
        } catch (err) {
            dispatch(setError(t('Dönem kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (visible == false) {
            setSelectedPeriod(null);
            form.resetFields();
        }
    }, [visible])


    const onFinish = async (values: any) => {
        await SavePeriodDatas(values)

        onClose();
    };

    const handleEdit = (record: PeriodDto) => {
        setSelectedPeriod(record);
        showDrawer();
    };

    const getStatusBadge = (record: Period) => {
        const now = moment();
        if (moment(record.endDate).isBefore(now)) {
            return <Badge status="error" text={t('bitti')} />;
        } else if (moment(record.startDate).isAfter(now)) {
            return <Badge status="warning" text={t('baslayacak')} />;
        } else {
            return <Badge status="success" text={t('devam-ediyor')} />;
        }
    };

    const getPeriodNo = () => {
        return `P-${moment().format("yyyy-MM-DD-mm")}`;
      }

    return (
        <div>
            <Button type="primary" onClick={showDrawer}>
                Dönem Ekle
            </Button>
            <Table dataSource={periods} rowKey="id" style={{ marginTop: 16 }} onRow={(record) => ({
                onClick: () => handleEdit(record),
            })}>
                <Column title={t('doenem-no')} dataIndex="periodNo" key="periodNo" />
                <Column title={t('doenem-adi')} dataIndex="periodName" key="periodName" />
                <Column title={t('baslangic-tarihi')}
                    dataIndex="startDate"
                    key="startDate"
                    render={(text, record: Period) => moment(text).format("DD/MM/yyyy")} />
                <Column title={t('bitis-tarihi')}
                    dataIndex="endDate"
                    key="endDate"
                    render={(text, record: Period) => moment(text).format("DD/MM/yyyy")} />
                <Column
                    title={t('durum')}
                    key="status"
                    render={(text, record: Period) => getStatusBadge(record)}
                />
            </Table>
            <Drawer
                title={selectedPeriod ? t('doenem-guencelle') : t('doenem-ekle')}
                width={400}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={selectedPeriod ? {
                        number: selectedPeriod.periodNo,
                        name: selectedPeriod.periodName,
                        startDate: dayjs(selectedPeriod.startDate),
                        endDate: dayjs(selectedPeriod.endDate),
                    } : {
                        startDate: dayjs().add(1, "day"),
                        endDate: dayjs().add(2, "day"),
                        number: getPeriodNo()
                    }}
                >
                    <Form.Item
                        name="number"
                        label={t('doenem-no-0')}
                        rules={[{ required: true, message: t('luetfen-doenem-numarasi-girin') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={t('doenem-adi-0')}
                        rules={[{ required: true, message: t('luetfen-doenem-adi-girin') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="startDate"
                        label={t('baslangic-tarihi-0')}
                        rules={[{ required: true, message: t('luetfen-baslangic-tarihini-girin') }]}
                        style={{ width: '100%' }}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'month'))}
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                    <Form.Item
                        name="endDate"
                        label={t('bitis-tarihi-0')}
                        rules={[{ required: true, message: t('luetfen-bitis-tarihini-girin') }]}
                        style={{ width: '100%' }}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            // disabledDate={(current) => current && (current < moment().subtract(1, 'month') || current > moment().add(1, 'year'))}
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button onClick={onClose}>{t('iptal')}</Button>
                            <Button type="primary" htmlType="submit">{t('save')}</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
};

export default PeriodDefinition;
