import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import persistedSlice from './persistedSlice'
import { coreApi } from '../api/core/BaseApi'
import appSlice from '../features/app/appSlice';
import authSlice from '../features/auth/authSlice';
import userSlice from '../features/users/userSlice';
import companySlice from '../features/companies/companySlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ["persisted"],
  debug: true
};

const persistedReducer = persistReducer(persistConfig,
  combineReducers({
    app: appSlice,
    auth: authSlice,
    persisted: persistedSlice,
    users: userSlice,
    companies: companySlice,

    [coreApi.reducerPath]: coreApi.reducer,
  }));

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      coreApi.middleware
    ]),
})

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

const persistor = persistStore(store);
export { store, persistor };