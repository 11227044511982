import { Button, Card, Progress, Slider, Space } from "antd";
import { ActivePerformanceCardDto, useLazyGetApiPerformanceCardsGetActivePerformanceCardsForUserByUserIdQuery, useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery, UserOkrDetailsDto } from "../../../api/services/performanceCards";
import PerformanceCardGroup from "./PerformanceCardGroup";
import { PerformanceCardGroupType } from "../PerformanceCardGroupType";
import OKRInputModal from "./Modal/OKRInputModal";
import { useEffect, useState } from "react";
import KRInputModal from "./Modal/KRInputModal";
import ProgressInputModal from "./Modal/ProgressInputModal";
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLoading, setError } from "../../../features/app/appSlice";
import { RootState } from "../../../app/store";

export interface IPerformanceCardModal {
    card: ActivePerformanceCardDto | null
}

const PerformanceCardModal: React.FC<IPerformanceCardModal> = ({ card }) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [fetchCardDetail, { data: CardDetails }] = useLazyGetApiPerformanceCardsGetPerformanceCardDetailsByCardIdQuery()
    const [totalPercent, setTotalPercent] = useState(0)

    useEffect(() => {
        if (card) {
            getCardDetails()
        }
    }, [card])

    const getCardDetails = async () => {
        try {
            dispatch(setLoading(true));

            await fetchCardDetail({
                cardId: card?.cardId ?? ""
            }).unwrap()

            calculateTotalPercent()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('kartlar sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const calculateTotalPercent = () => {
        var total = CardDetails?.okRs?.reduce((n, newVal, index) => (n + (newVal.progress ?? 0)), 0) ?? 0
        var len = (CardDetails?.okRs ?? []).length
        var percent = total / (len == 0 ? 1 : len)
        setTotalPercent(percent)
    }

    return (
        <div>
            <Space direction="vertical" size="middle" className="w-full mt-3">
                <div className="flex flex-row mb-2 justify-between">
                    <div></div>
                    <div className="flex flex-row items-center">
                        {
                            (CardDetails?.okRs ?? []).length > 0 &&
                            <>
                                <h4 className="mr-2 font-semibold">OKR İlerlemesi</h4>
                                <Progress type="circle" percent={totalPercent} size={30} />
                            </>
                        }

                    </div>
                </div>

                {
                    <PerformanceCardGroup type={PerformanceCardGroupType.Active}
                        card={card}
                        CardDetails={CardDetails}
                        needsRecalculation={(okrs: UserOkrDetailsDto[]) => {
                            calculateTotalPercent()
                        }} />
                }

                {
                    CardDetails?.okRs?.filter(k => k.status == 1).map(k =>
                        <PerformanceCardGroup type={PerformanceCardGroupType.Completed} card={card} CardDetails={CardDetails} />
                    )
                }

                {
                    CardDetails?.okRs?.filter(k => k.status == 2).map(k =>
                        <PerformanceCardGroup type={PerformanceCardGroupType.Cancelled} card={card} CardDetails={CardDetails} />
                    )
                }
            </Space>
        </div >
    );
}

export default PerformanceCardModal;
