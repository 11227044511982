import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Select, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import StrategicOKRGoalTableRow from './StrategicOKRGoalTableRow';
import StrategicOKRGoalTableSummary from './StrategicOKRGoalTableSummary';
import TextArea from 'antd/es/input/TextArea';
import { kpiGoalCalculationSource } from '../../data/data';
import { DataItem } from '../../models/DataItem';
import { PerformanceGoalDetailDto, PerformanceGoalDto } from '../../api/services/PerformanceGoal';
import { WeightenedOKRId } from '../../data/constants';
import { PerformanceSystemDto } from '../../api/services/company';
import { Guid } from 'typescript-guid';

const initialRows: PerformanceGoalDetailDto[] = [
  {id:  Guid.create().toString(), strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0 },
];



export interface StrategicOKRGoalTableParams {
  goal: any | undefined
  selectedPerformanceSystem: PerformanceSystemDto | undefined
}

export interface CalculatedFields {
  weight: number
}

const StrategicOKRGoalTable: React.FC<StrategicOKRGoalTableParams> = ({ goal, selectedPerformanceSystem }) => {
  const [dataSource, setDataSource] = useState<PerformanceGoalDetailDto[]>([...initialRows]);
  const [CalculatedFields, setCalculatedFields] = useState<CalculatedFields>({
    weight: 0
  })

  useEffect(() => {
    if (selectedPerformanceSystem) {
      if (goal?.rows.length > 0) {
        setDataSource(goal?.rows)
      }
    }
  }, [selectedPerformanceSystem])

  const handleAddRow = () => {
    const newRow: PerformanceGoalDetailDto = {
      id: Guid.create().toString(),
      strategicGoal: '',
      kpi: '',
      weight: 0,
      source: '',
      minResult: 0,
      targetResult: 0,
      maxResult: 0,
    };
    setDataSource([...dataSource, newRow]);
  };

  const handleChange = (key: string, field: string, value: any) => {
    const newData = dataSource.map((row) => {
      if (row.id === key) {
        return { ...row, [field]: value };
      }
      return row;
    });
    
    setDataSource(newData);
  };

  useEffect(() => {
    if(dataSource) {
        var cw = calculateTotal(dataSource, 'weight')
        setCalculatedFields({ weight: cw })
    }
  }, [dataSource])
  

  const calculateTotal = (ds: PerformanceGoalDetailDto[], field: keyof PerformanceGoalDetailDto) => {
    return ds.reduce((sum, row) => sum + (Number(row[field]) || 0), 0);
  };

  const columns = [
    {
      title: 'Stratejik OKR',
      dataIndex: 'okr',
      key: 'okr',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          className='mb-0'
          name={["rows", index, "okr"]}
          rules={[
            { required: true, message: "Stratejik OKR yazmalısınız" }
          ]}
          initialValue={text}
        >
          <TextArea
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'okr', e.target.value)}
            style={{ height: '40px', resize: 'none' }} // TextArea için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      hidden: selectedPerformanceSystem?.okrDetails?.[0].okrWeightId != WeightenedOKRId,
      title: 'OKR Ağırlık %',
      dataIndex: 'weight',
      key: 'weight',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          className='mb-0'
          name={["rows", index, "weight"]}
          rules={[
            { required: true, message: "OKR Ağırlık yazmalısınız" }
          ]}
          initialValue={text}
        >
          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'weight', parseFloat(e.target.value))}
            suffix="%"
            style={{ height: '40px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    },
    {
      hidden: selectedPerformanceSystem?.okrDetails?.[0].okrWeightId != WeightenedOKRId,
      title: 'Hedef Ölçüm Kaynağı',
      dataIndex: 'goalMeasurementSource',
      key: 'goalMeasurementSource',
      render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "goalMeasurementSource"]}
          className='mb-0'
          rules={[
            { required: true, message: "Hedef Ölçüm Kaynağı yazmalısınız" }
          ]}
          initialValue={text}
        >
          <Select
            value={text}
            onChange={(value) => handleChange(record.id ?? "", 'source', value)}
            style={{ height: '40px' }} // Select için yükseklik ayarı
          >
            {kpiGoalCalculationSource.map((item: DataItem) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      ),
    },
    {
      hidden: selectedPerformanceSystem?.okrDetails?.[0].okrWeightId != WeightenedOKRId,
      title: 'Hedef Sonuç',
      dataIndex: 'targetResult',
      key: 'targetResult',
      render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
        <Form.Item
          name={["rows", index, "targetResult"]}
          className='mb-0'
          rules={[
            { required: true, message: "Hedef Sonuç yazmalısınız" }
          ]}
          initialValue={text}
        >

          <Input
            type="number"
            min={0}
            max={100}
            value={text}
            onChange={(e) => handleChange(record.id ?? "", 'targetResult', parseFloat(e.target.value))}
            suffix="%"
            style={{ height: '40px' }} // Input için yükseklik ayarı
          />
        </Form.Item>

      ),
    }
  ];


  return (
    <>
      <Button onClick={handleAddRow} type="primary" icon={<PlusOutlined />} style={{ marginBottom: 16 }}>
        Satır Ekle
      </Button>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        rowKey="key"
        summary={() => (selectedPerformanceSystem?.okrDetails?.[0].okrWeightId == WeightenedOKRId) ? <StrategicOKRGoalTableSummary total={CalculatedFields} /> : null}
      />
    </>
  );
};

export default StrategicOKRGoalTable;
