import React, { useEffect } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import { t } from 'i18next';
import { useLazyGetApiCompanyByGroupByGroupIdQuery } from '../../api/services/company';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

const { Option } = Select;

interface UserFormProps {
    form: any;
    isEditMode: boolean;
    onFinish: (values: any) => void
}

const UserForm: React.FC<UserFormProps> = ({ form, isEditMode, onFinish }) => {
    const user = useSelector((state: RootState) => state.auth.user);
    const [getCompaniesForGroup, { data: companies, error }] = useLazyGetApiCompanyByGroupByGroupIdQuery()

    useEffect(() => {
      if(user) {
        getCompaniesForGroup({
            groupId: user.company?.companyGroupId ?? ""
        })
      }
    }, [user])
    
    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
                name="employeeNumber"
                label={t('employee-number')}
                rules={[{ required: true, message: t('please-enter-the-employee-number') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="firstName"
                label={t('first-name')}
                rules={[{ required: true, message: t('please-enter-the-first-name') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="lastName"
                label={t('last-name')}
                rules={[{ required: true, message: t('please-enter-the-last-name') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="function"
                label={t('function')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="subFunction"
                label={t('sub-function')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="department"
                label={t('department')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="subDepartment"
                label={t('sub-department')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="title"
                label={t('title')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="titleGroup"
                label={t('title-group')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="employeeType"
                label={t('employee-type')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="workLocation"
                label={t('work-location')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="startDate"
                label={t('start-date')}
                rules={[{ required: true, message: t('please-select-the-start-date') }]}
            >
                <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
                name="companyId"
                label="Company"
                rules={[{ required: true, message: 'Please select a company' }]}
            >
                <Select>
                    {(companies ?? []).map((company) => (
                        <Option key={company.id} value={company.id}>
                            {company.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="manager"
                label={t('manager')}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="email"
                label={t('email')}
                rules={[
                    { required: true, message: t('please-enter-the-email') },
                    { type: 'email', message: t('please-enter-a-valid-email') },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="phone"
                label={t('phone')}
                rules={[{ required: true, message: t('please-enter-the-phone-number') }]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
};

export default UserForm;
