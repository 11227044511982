import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiRolePermissionAssignUsersToRoleByCompanyGroupId: build.mutation<
      PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiResponse,
      PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign-users-to-role/${queryArg.companyGroupId}`,
        method: "POST",
        body: queryArg.roleAssignmentDto,
      }),
    }),
    getApiRolePermissionRolesWithUsersByCompanyGroupId: build.query<
      GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiResponse,
      GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-users/${queryArg.companyGroupId}`,
      }),
    }),
    postApiTargetAudienceResolveSuitableUsers: build.mutation<
      PostApiTargetAudienceResolveSuitableUsersApiResponse,
      PostApiTargetAudienceResolveSuitableUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TargetAudience/resolve-suitable-users`,
        method: "POST",
        body: queryArg.resolveSuitableUsersRequest,
      }),
    }),
    getApiUser: build.query<GetApiUserApiResponse, GetApiUserApiArg>({
      query: () => ({ url: `/api/User` }),
    }),
    postApiUser: build.mutation<PostApiUserApiResponse, PostApiUserApiArg>({
      query: (queryArg) => ({
        url: `/api/User`,
        method: "POST",
        body: queryArg.createUserDto,
      }),
    }),
    getApiUserById: build.query<
      GetApiUserByIdApiResponse,
      GetApiUserByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/User/${queryArg.id}` }),
    }),
    putApiUserById: build.mutation<
      PutApiUserByIdApiResponse,
      PutApiUserByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateUserDto,
      }),
    }),
    deleteApiUserById: build.mutation<
      DeleteApiUserByIdApiResponse,
      DeleteApiUserByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiUserByCompanyByCompanyId: build.query<
      GetApiUserByCompanyByCompanyIdApiResponse,
      GetApiUserByCompanyByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompany/${queryArg.companyId}`,
      }),
    }),
    getApiUserByCompanyGroupByCompanyGroupId: build.query<
      GetApiUserByCompanyGroupByCompanyGroupIdApiResponse,
      GetApiUserByCompanyGroupByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompanyGroup/${queryArg.companyGroupId}`,
      }),
    }),
    postApiUserImportAndCompare: build.mutation<
      PostApiUserImportAndCompareApiResponse,
      PostApiUserImportAndCompareApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/import-and-compare`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postApiUserProcessImportedUsers: build.mutation<
      PostApiUserProcessImportedUsersApiResponse,
      PostApiUserProcessImportedUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/process-imported-users`,
        method: "POST",
        body: queryArg.importResultDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiResponse =
  unknown;
export type PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiArg = {
  companyGroupId: string;
  roleAssignmentDto: RoleAssignmentDto;
};
export type GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiResponse =
  /** status 200 OK */ RoleWithUsersDto[];
export type GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiTargetAudienceResolveSuitableUsersApiResponse =
  /** status 200 OK */ UserDto[];
export type PostApiTargetAudienceResolveSuitableUsersApiArg = {
  resolveSuitableUsersRequest: ResolveSuitableUsersRequest;
};
export type GetApiUserApiResponse = /** status 200 OK */ UserDto[];
export type GetApiUserApiArg = void;
export type PostApiUserApiResponse = /** status 200 OK */ UserDto;
export type PostApiUserApiArg = {
  createUserDto: CreateUserDto;
};
export type GetApiUserByIdApiResponse = /** status 200 OK */ UserDto;
export type GetApiUserByIdApiArg = {
  id: string;
};
export type PutApiUserByIdApiResponse = unknown;
export type PutApiUserByIdApiArg = {
  id: string;
  updateUserDto: UpdateUserDto;
};
export type DeleteApiUserByIdApiResponse = unknown;
export type DeleteApiUserByIdApiArg = {
  id: string;
};
export type GetApiUserByCompanyByCompanyIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyByCompanyIdApiArg = {
  companyId: string;
};
export type GetApiUserByCompanyGroupByCompanyGroupIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyGroupByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiUserImportAndCompareApiResponse =
  /** status 200 Import successful */ ImportResultDto;
export type PostApiUserImportAndCompareApiArg = {
  body: FormData
};
export type PostApiUserProcessImportedUsersApiResponse = unknown;
export type PostApiUserProcessImportedUsersApiArg = {
  importResultDto: ImportResultDto;
};
export type FilterDto = {
  action?: string | null;
  variable?: string | null;
  value?: string[] | null;
};
export type RoleAssignmentDto = {
  roleId?: string;
  filters?: FilterDto[] | null;
};
export type RoleWithUsersDto = {
  roleId?: string;
  roleName?: string | null;
  userCount?: number;
  formula?: FilterDto[] | null;
};
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export type ResolveSuitableUsersRequest = {
  id?: string;
  companyId?: string;
  targetAudienceName?: string | null;
  targetAudienceNo?: string | null;
  filters?: FilterDto[] | null;
  companyGroupId?: string;
};
export type CreateUserDto = {
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type UpdateUserDto = {
  userName?: string | null;
  password?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type UserImportResultDto = {
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  status?: string | null;
};
export type CompanyImportResultDto = {
  companyName?: string | null;
  address?: string | null;
  email?: string | null;
  status?: string | null;
};
export type ImportResultDto = {
  addableUsers?: UserImportResultDto[] | null;
  missingCompanies?: CompanyImportResultDto[] | null;
  usersLinkedToMissingCompanies?: UserImportResultDto[] | null;
};
export const {
  usePostApiRolePermissionAssignUsersToRoleByCompanyGroupIdMutation,
  useGetApiRolePermissionRolesWithUsersByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionRolesWithUsersByCompanyGroupIdQuery,
  usePostApiTargetAudienceResolveSuitableUsersMutation,
  useGetApiUserQuery,
  useLazyGetApiUserQuery,
  usePostApiUserMutation,
  useGetApiUserByIdQuery,
  useLazyGetApiUserByIdQuery,
  usePutApiUserByIdMutation,
  useDeleteApiUserByIdMutation,
  useGetApiUserByCompanyByCompanyIdQuery,
  useLazyGetApiUserByCompanyByCompanyIdQuery,
  useGetApiUserByCompanyGroupByCompanyGroupIdQuery,
  useLazyGetApiUserByCompanyGroupByCompanyGroupIdQuery,
  usePostApiUserImportAndCompareMutation,
  usePostApiUserProcessImportedUsersMutation,
} = injectedRtkApi;
