import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { UserDto } from '../../api/services/user';
import { RootState } from '../../app/store';

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  user: UserDto | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  user: null
};

export const login = createAsyncThunk(
  'auth/login',
  async (credentials: { username: string; password: string }) => {
    const response = await axios.post('/api/login', credentials);
    return response.data;
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (token: string) => {
    const response = await axios.post('/api/refresh-token', { token });
    return response.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
    },
    setUserInfo(state, user: PayloadAction<UserDto | null>) {
      state.user = user.payload;
    }
  }
});

export const { logout, setUserInfo } = authSlice.actions;
export const userInfo = (state: RootState) => state.auth.user;

export default authSlice.reducer;
