import React, { useState } from 'react';
import { Table, Modal, Button, Form, Select, DatePicker, message } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;

interface MeetingSchedule {
    id: string;
    period: string;
    system: string;
    meetingName: string;
    startDate: string;
    endDate: string;
}


const getMeetingSchedules = () => {
    return [
      {
        id: '1',
        period: '2023 Q1',
        system: 'OKR',
        meetingName: 'Quarterly Review',
        startDate: '2024-01-01',
        endDate: '2024-03-31',
      },
      {
        id: '2',
        period: '2023 Q2',
        system: 'KPI',
        meetingName: 'Mid-Year Review',
        startDate: '2024-04-01',
        endDate: '2024-06-30',
      },
    ];
  };

const periods = [
    { id: '2023 Q1', name: '2023 Q1', startDate: '2024-01-01', endDate: '2024-03-31' },
    { id: '2023 Q2', name: '2023 Q2', startDate: '2024-04-01', endDate: '2024-06-30' },
    { id: '2023 Q3', name: '2023 Q3', startDate: '2024-07-01', endDate: '2024-09-30' },
    { id: '2023 Q4', name: '2023 Q4', startDate: '2024-10-01', endDate: '2024-12-31' },
];

const OneOnOneMeetingSchedule: React.FC = () => {
    const [schedules, setSchedules] = useState<MeetingSchedule[]>(getMeetingSchedules());
    const [selectedSchedule, setSelectedSchedule] = useState<any>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null); // Seçilen dönem
    const [form] = Form.useForm();

    // Dönemin başlangıç ve bitiş tarihine göre tarihleri engelle
    const disabledStartDate = (current: dayjs.Dayjs) => {
        if (!selectedPeriod) return false;
        const periodStart = dayjs(selectedPeriod.startDate);
        const periodEnd = dayjs(selectedPeriod.endDate).add(1, 'months');
        return current.isBefore(periodStart) || current.isAfter(periodEnd);
    };

    const disabledEndDate = (current: dayjs.Dayjs) => {
        if (!selectedPeriod) return false;
        const periodStart = dayjs(selectedPeriod.startDate);
        const periodEnd = dayjs(selectedPeriod.endDate).add(1, 'months');
        return current.isBefore(periodStart) || current.isAfter(periodEnd);
    };

    // Yeni takvim eklemek için modalı açar
    const handleAddSchedule = () => {
        setSelectedSchedule(null);
        form.resetFields();
        setModalVisible(true);
    };

    // Tablodaki bir kayda tıklandığında modalda gösterir
    const handleRowClick = (record: any) => {
        const period = periods.find((p) => p.id === record.period);
        setSelectedPeriod(period);
        setSelectedSchedule(record);
        setModalVisible(true);
        form.setFieldsValue({
            period: record.period,
            system: record.system,
            meetingName: record.meetingName,
            startDate: dayjs(record.startDate),
            endDate: dayjs(record.endDate),
        });
    };

    // Dönem seçildiğinde period'u güncelle
    const handlePeriodChange = (value: string) => {
        const period = periods.find((p) => p.id === value);
        setSelectedPeriod(period);
    };

    // Kaydet butonuna basıldığında yeni takvim ekler veya mevcut kaydı günceller
    const handleSave = (values: any) => {
        const newSchedule = {
            id: selectedSchedule ? selectedSchedule.id : (schedules.length + 1).toString(),
            period: values.period,
            system: values.system,
            meetingName: values.meetingName,
            startDate: values.startDate.format('YYYY-MM-DD'),
            endDate: values.endDate.format('YYYY-MM-DD'),
        };

        if (selectedSchedule) {
            const updatedSchedules = schedules.map((item) =>
                item.id === selectedSchedule.id ? newSchedule : item
            );
            setSchedules(updatedSchedules);
        } else {
            setSchedules([...schedules, newSchedule]);
        }

        setModalVisible(false);
        setSelectedSchedule(null);
    };

    return (
        <>
            <Button type="primary" onClick={handleAddSchedule} style={{ marginBottom: 16 }}>
                1-1 Görüşme Takvim Ekle
            </Button>

            <Table
                dataSource={schedules}
                columns={[
                    { title: 'Dönem', dataIndex: 'period', key: 'period' },
                    { title: 'Performans Sistemi', dataIndex: 'system', key: 'system' },
                    { title: 'Görüşme Tipi', dataIndex: 'meetingName', key: 'meetingName' },
                    { title: 'Başlangıç Tarihi', dataIndex: 'startDate', key: 'startDate' },
                    { title: 'Bitiş Tarihi', dataIndex: 'endDate', key: 'endDate' },
                ]}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={false}
            />

            <Modal
                title={selectedSchedule ? '1-1 Görüşme Takvimini Güncelle' : 'Yeni 1-1 Görüşme Takvimi Ekle'}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setModalVisible(false)}>
                        İptal
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => form.submit()}>
                        Kaydet
                    </Button>,
                ]}
            >
                <Form form={form} onFinish={handleSave} layout="vertical">
                    <Form.Item
                        name="period"
                        label="Dönem"
                        rules={[{ required: true, message: 'Dönem seçmelisiniz!' }]}
                    >
                        <Select
                            placeholder="Dönemi seçin"
                            style={{ width: '100%' }}
                            onChange={handlePeriodChange}
                        >
                            {periods.map((period) => (
                                <Option key={period.id} value={period.id}>
                                    {period.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="system"
                        label="Performans Sistemi"
                        rules={[{ required: true, message: 'Performans sistemi seçmelisiniz!' }]}
                    >
                        <Select placeholder="Performans sistemini seçin" style={{ width: '100%' }}>
                            <Option value="OKR">OKR</Option>
                            <Option value="KPI">KPI</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="meetingName"
                        label="Görüşme Tipi"
                        rules={[{ required: true, message: 'Görüşme tipini seçmelisiniz!' }]}
                    >
                        <Select placeholder="Görüşme adını seçin" style={{ width: '100%' }}>
                            <Option value="Quarterly Review">Quarterly Review</Option>
                            <Option value="Mid-Year Review">Mid-Year Review</Option>
                            <Option value="Annual Performance Review">Annual Performance Review</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="startDate"
                        label="Başlangıç Tarihi"
                        rules={[{ required: true, message: 'Başlangıç tarihi seçmelisiniz!' }]}
                    >
                        <DatePicker disabledDate={disabledStartDate} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        name="endDate"
                        label="Bitiş Tarihi"
                        rules={[{ required: true, message: 'Bitiş tarihi seçmelisiniz!' }]}
                    >
                        <DatePicker disabledDate={disabledEndDate} style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default OneOnOneMeetingSchedule;
