import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setLoading, setError } from '../../features/app/appSlice';
import { Button, Drawer, Form, Spin, Modal, notification } from 'antd';
import { CompanyDto, useLazyGetApiCompanyByGroupByGroupIdQuery, usePostApiCompanyMutation, usePutApiCompanyByIdMutation, UserDto } from '../../api/services/company';
import { t } from 'i18next';
import UserForm from './CompanyForm';
import CompanyTable from './CompanyTable';
import moment from 'moment';
import CompanyForm from './CompanyForm';

const Companies: React.FC = () => {
  const dispatch = useDispatch();
  const [getCompanies, { data: companies, isLoading: loading, error }] = useLazyGetApiCompanyByGroupByGroupIdQuery()
  const user = useSelector((state: RootState) => state.auth.user);
  const [createCompany, { data: createdCompany, isSuccess: companyCreationSuccess, isLoading: companyCreating, error: creationError }] = usePostApiCompanyMutation()
  const [updateCompany, { isSuccess: companyUpdateSuccess, isLoading: companyUpdating, error: updateError }] = usePutApiCompanyByIdMutation()

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto | null>(null);
  const [form] = Form.useForm();
  const { confirm } = Modal;

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
    form.resetFields();
  };
  
  const loadCompanies = async () => {
    try {
      dispatch(setLoading(true));
      await getCompanies({
        groupId: user?.company?.companyGroupId ?? ""
      }).unwrap()
    } catch (err) {
      dispatch(setError(t('sirketleri-cekerken-problem-olustu')));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (user)
      loadCompanies();
  }, [user]);

  const onFinish = (values: any) => {

    const createUserFunc = async () => {
      dispatch(setLoading(true));
      try {
        values.companyGroupId = user?.company?.companyGroupId ?? "";
        await createCompany({
          createCompanyDto: values
        })
      } catch (err) {
        dispatch(setError(t('sirket-eklerken-hata-olustu')));
      } finally {
        dispatch(setLoading(false));
      }
    };

    const updateUserFunc = async (values: any) => {

      try {
        dispatch(setLoading(true));
        values.companyGroupId = user?.company?.companyGroupId ?? "";
        await updateCompany({
          id: selectedCompany?.id ?? "",
          updateCompanyDto: values
        }).unwrap()
      } catch (err) {
        dispatch(setError(t('failed-to-add-user')));
      } finally {
        dispatch(setLoading(false));
      }
    };

    if (!isEditMode)
      createUserFunc()
    else
      updateUserFunc(values)
  };

  useEffect(() => {
    if (companyCreationSuccess) {
      notification.success({ message: t('yeni-sirket-eklendi') });
      closeDrawer();
      loadCompanies();
    }
  }, [companyCreationSuccess])

  useEffect(() => {
    if (companyUpdateSuccess) {
      closeDrawer();
      loadCompanies();
    }
  }, [companyUpdateSuccess])

  const handleEdit = (company: CompanyDto) => {
    setSelectedCompany(company);
    setIsEditMode(true);
    form.setFieldsValue({
      ...company,
    });
    showDrawer();
  };

  const handleAdd = () => {
    setIsEditMode(false);
    showDrawer();
  }

  if (loading) return null;
  if (error) return <div>{t('sirket-eklerken-hata-olustu')}</div>;

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={handleAdd}>
        {t('add-company')}
      </Button>
      <Drawer
        title={isEditMode ? t('sirket-duezenleme') : t('sirket-ekleme')}
        width={720}
        onClose={closeDrawer}
        visible={isDrawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Spin spinning={loading}>
          <CompanyForm form={form} selectedCompany={selectedCompany} isEditMode={isEditMode} onFinish={onFinish} />
          <Form.Item>
            <Button type="primary" onClick={form.submit}>
              {t('save')}
            </Button>
          </Form.Item>
        </Spin>
      </Drawer>
      <CompanyTable companies={companies ?? []} handleEdit={handleEdit} />
    </div>
  );
};

export default Companies;
