import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiAuthLogin: build.mutation<
      PostApiAuthLoginApiResponse,
      PostApiAuthLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Auth/login`,
        method: "POST",
        body: queryArg.loginModel,
      }),
    }),
    postApiAuthRefreshToken: build.mutation<
      PostApiAuthRefreshTokenApiResponse,
      PostApiAuthRefreshTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Auth/refresh-token`,
        method: "POST",
        body: queryArg.tokenModel,
      }),
    }),
    getApiAuthMe: build.query<GetApiAuthMeApiResponse, GetApiAuthMeApiArg>({
      query: () => ({ url: `/api/Auth/me` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiAuthLoginApiResponse =
  /** status 200 Login successful */ TokenResponseModel;
export type PostApiAuthLoginApiArg = {
  loginModel: LoginModel;
};
export type PostApiAuthRefreshTokenApiResponse =
  /** status 200 Token refresh successful */ TokenResponseModel;
export type PostApiAuthRefreshTokenApiArg = {
  tokenModel: TokenModel;
};
export type GetApiAuthMeApiResponse = unknown;
export type GetApiAuthMeApiArg = void;
export type TokenResponseModel = {
  token?: string | null;
  expiration?: string;
  refreshToken?: string | null;
  refreshTokenExpiration?: string;
};
export type LoginModel = {
  username?: string | null;
  password?: string | null;
};
export type TokenModel = {
  token?: string | null;
  refreshToken?: string | null;
};
export const {
  usePostApiAuthLoginMutation,
  usePostApiAuthRefreshTokenMutation,
  useGetApiAuthMeQuery,
  useLazyGetApiAuthMeQuery,
} = injectedRtkApi;
