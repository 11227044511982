import React, { useEffect } from 'react';
import { Form, Input, Button, Alert, Checkbox, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLazyGetApiAuthMeQuery, usePostApiAuthLoginMutation } from '../api/services/auth';
import { setAccessToken, setRefreshToken, toggleLoggedIn } from '../app/persistedSlice';
import { setError, setLoading } from '../features/app/appSlice';
import { setUserInfo } from '../features/auth/authSlice';
import { t } from 'i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

const Login: React.FC = () => {
  const [login, { data: loginData, isLoading, isSuccess: isLoginSuccess, error: loginError }] = usePostApiAuthLoginMutation()

  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = async (values: any) => {
    await login({
      loginModel: {
        username: values.email,
        password: values.password
      }
    })
  };

  useEffect(() => {
    dispatch(setLoading(isLoading))
  }, [isLoading])

  useEffect(() => {
    if (isLoginSuccess && loginData) {
      dispatch(setAccessToken(loginData.token ?? ""))
      dispatch(setRefreshToken(loginData.refreshToken ?? ""))
      dispatch(toggleLoggedIn(true))

      history.push('/');
    }
  }, [isLoginSuccess])

  useEffect(() => {
    if (loginError) {
      // dispatch(setError(loginError.))
      console.log(loginError)
    }
  }, [loginError])


  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <Typography.Title level={1} className='mb-3'>
        Co Hr
      </Typography.Title>
      {loginError &&
        <Alert
          message={t('error')}
          description={t('please-check-your-login-informations')}
          type="error"
          showIcon
          className='w-1/3 mb-3'
        />
      }
      <Form
        name="login"
        onFinish={onFinish}
        className="login-form w-1/3 max-w-[300]"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: t('please-input-your-email') }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('e-mail')} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: t('please-input-your-password') }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder={t('password-0')} />
        </Form.Item>

        <Form.Item>

          <a className="login-form-forgot float-right" href="">
            {t('forgot-password')}
          </a>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button w-full">
            {t('log-in')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
