import React, { useEffect, useState } from 'react';
import { Table, Modal, Button, Steps, Form, Badge } from 'antd';
import ParameterSelectionStep from './PerformanceCardSteps/ParameterSelectionStep';
import PerformanceSummaryStep from './PerformanceCardSteps/PerformanceSummaryStep';
import ResultStep from './PerformanceCardSteps/ResultStep';
import { PerformanceCardReviewDto, PerformanceCardReviewRequestDto, PeriodDto, useLazyGetApiPerformanceCardsGetAllPerformanceCardsQuery, useLazyGetApiPerformanceCardsGetPerformanceCardQuery, usePostApiPerformanceCardsCreatePerformanceCardWithAssignmentsMutation } from '../../api/services/performanceCards';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setError } from '../../features/app/appSlice';
import { RootState } from '../../app/store';
import moment from 'moment';
import PerformanceCardDetails from './PerformanceCardSteps/PerformanceCardDetails';

const { Step } = Steps;

const PerformanceCardScreen: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalDisplayVisible, setModalDisplayVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedCard, setSelectedCard] = useState<any>(null);
    const [resultData, setResultData] = useState<any>(null);
    const [performanceReviewData, setPerformanceReviewData] = useState<PerformanceCardReviewDto | null>(null)
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [fetchPerformanceCards, { data: performanceCardList }] = useLazyGetApiPerformanceCardsGetAllPerformanceCardsQuery()
    const [createPerformaceCard] = usePostApiPerformanceCardsCreatePerformanceCardWithAssignmentsMutation()
    const [cardOptions, setCardOptions] = useState<PerformanceCardReviewRequestDto | null>()
    const [getCard, { data: selectedCardDetail }] = useLazyGetApiPerformanceCardsGetPerformanceCardQuery();


    useEffect(() => {
        if (user) {
            getCardList()
        }
    }, [user])

    const getCardList = async () => {
        try {
            dispatch(setLoading(true));
            await fetchPerformanceCards({
                companyGroupId: user?.company?.companyGroupId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const createCard = async () => {
        try {
            if (cardOptions) {
                dispatch(setLoading(true));
                await createPerformaceCard({
                    performanceCardReviewRequestDto: cardOptions
                }).unwrap()
            }
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Parametreler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getSelectedCard = async (id: string) => {
        try {
            dispatch(setLoading(true));
            await getCard({
                id: id
            }).unwrap()



            setModalDisplayVisible(true);
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('kart sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        if (selectedCard) {
            getSelectedCard(selectedCard.id)
        }
    }, [selectedCard])


    const handleAddCard = () => {
        setSelectedCard(null);
        setModalVisible(true);
    };

    const handleRowClick = (record: any) => {
        setSelectedCard(record);
    };

    const handleNextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleFinish = async () => {
        setModalVisible(false);
        setCurrentStep(0);
    }

    const handleCancel = () => {
        setModalVisible(false);
        setCurrentStep(0);
    };

    const handleDisplayCancel = () => {
        setModalDisplayVisible(false);
    };

    const handleFetchResultData = async () => {
        await createCard()
        const result = {
            personCount: performanceReviewData?.targetAudienceList?.length,
            cardName: performanceReviewData?.cardName ?? "",
        };
        setResultData(result);
        handleNextStep();
        getCardList();
    };

    const columns = [
        { title: 'Kart Adı', dataIndex: 'cardName', key: 'cardName' },
        {
            title: 'Performans Dönemi', dataIndex: 'periodName', key: 'periodName', render: (text: any) => {
                return text.periodName
            }
        },
        {
            title: 'Performans Sistemi', dataIndex: 'performanceSystemName', key: 'performanceSystemName', render: (text: any) => {
                return text.systemName
            }
        },
        {
            title: 'Durum', dataIndex: 'periodName', key: 'periodName', render: (text: any) => {
                return getStatusBadge(text)
            }
        },
    ];

    const getStatusBadge = (record: PeriodDto) => {
        const now = moment();
        if (moment(record.endDate).isBefore(now)) {
            return <Badge status="error" text={t('bitti')} />;
        } else if (moment(record.startDate).isAfter(now)) {
            return <Badge status="warning" text={t('baslayacak')} />;
        } else {
            return <Badge status="success" text={t('devam-ediyor')} />;
        }
    };

    const handleNextToSummary = (reviewedData: PerformanceCardReviewDto, cardOptions: PerformanceCardReviewRequestDto) => {
        setPerformanceReviewData(reviewedData)
        setCardOptions(cardOptions)
        handleNextStep()
    }

    return (
        <>
            <Button type="primary" onClick={handleAddCard} style={{ marginBottom: 16 }}>
                Yeni Kart Oluştur
            </Button>

            <Table
                dataSource={performanceCardList ?? []}
                columns={columns}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={false}
            />

            <Modal
                title={selectedCard ? 'Performans Kartı İşlemleri' : 'Yeni Performans Kartı Oluştur'}
                visible={modalVisible}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={800}
            >
                <Steps current={currentStep} style={{ marginBottom: 24 }}>
                    <Step title="Parametre Seçimi" />
                    <Step title="Performans Kartı Özeti" />
                    <Step title="Sonuç" />
                </Steps>

                {/* Step içerikleri */}
                {currentStep === 0 && (
                    <ParameterSelectionStep
                        onNext={handleNextToSummary}
                        onCancel={handleCancel}
                        form={form} />
                )}
                {currentStep === 1 && performanceReviewData && (
                    <PerformanceSummaryStep
                        reviewData={performanceReviewData}
                        onNext={handleFetchResultData}
                        onPrevious={handlePreviousStep}
                    />
                )}
                {currentStep === 2 &&
                    <ResultStep resultData={resultData}
                        onComplete={handleFinish} />}
            </Modal>

            <Modal
                title={'Performans Kartı İşlemleri'}
                visible={modalDisplayVisible}
                maskClosable={true}
                onCancel={handleDisplayCancel}
                footer={null}
                width={800}
            >
                <PerformanceCardDetails
                    details={selectedCardDetail ?? null}
                />
            </Modal>
        </>
    );
};

export default PerformanceCardScreen;
