import React from 'react';
import { Table } from 'antd';
import { PerformanceSystem } from './dummyData';
import { findNameById } from './helpers';
import { periods } from '../../data/data';
import { PerformanceSystemDto } from '../../api/services/performanceSystem';
import { PerformanceSystemType } from '../../enums/PerformanceSystemType';

interface PerformanceSystemTableProps {
  data: PerformanceSystemDto[] | undefined;
  onRowClick: (record: PerformanceSystemDto) => void;
}

const PerformanceSystemTable: React.FC<PerformanceSystemTableProps> = ({ data, onRowClick }) => {
  const columns = [
    {
      title: 'Sistem No',
      dataIndex: 'systemNumber',
      key: 'systemNumber',
    },
    {
      title: 'Sistem Adı',
      dataIndex: 'systemName',
      key: 'systemName',
      // render: (text: string) => findNameById(text, periods)
    },
    {
      title: 'Performans Sistemi',
      dataIndex: 'performanceSystemType',
      key: 'performanceSystemType',
      render: (text: string) => (text == "1") ? PerformanceSystemType.OKR : PerformanceSystemType.KPI
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      onRow={(record) => ({
        onClick: () => onRowClick(record),
      })}
    />
  );
};

export default PerformanceSystemTable;
