import React from 'react';
import { Card, Typography, Button } from 'antd';

const { Text, Title } = Typography;

interface ResultStepProps {
  resultData: any;
  onComplete: () => void;
}

const ResultStep: React.FC<ResultStepProps> = ({ resultData, onComplete }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
      <Card
        style={{
          textAlign: 'center',
          borderRadius: 8,
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          width: '60%',
        }}
      >
        <Title level={3} style={{ marginBottom: 16 }}>
          Performans Kartı Tanımlandı!
        </Title>
        <Text strong style={{ fontSize: 16 }}>
          {resultData ? `${resultData.personCount} kişiye ${resultData.cardName} isminde performans kartı tanımlandı.` : 'Veri yükleniyor...'}
        </Text>
      </Card>
      <Button type="primary" style={{ marginTop: 24 }} onClick={onComplete}>
        Kapat
      </Button>
    </div>
  );
};

export default ResultStep;
