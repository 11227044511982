import React, { useEffect, useState } from 'react';
import { Table, Modal, Button, Select, Form, Input } from 'antd';
import { PermissionDto, RoleWithPermissionsDto, useLazyGetApiRolePermissionPermissionsByCompanyGroupIdQuery, useLazyGetApiRolePermissionRolesByCompanyGroupIdQuery, useLazyGetApiRolePermissionRolesWithPermissionsByCompanyGroupIdQuery, usePostApiRolePermissionAssignMutation } from '../../api/services/rolePermission';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { t } from 'i18next';
import { setLoading, setError } from '../../features/app/appSlice';

const { Option } = Select;

const SystemRoleManagement: React.FC = () => {
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);

    const [fetchRolePermissions, { data: rolePermissions }] = useLazyGetApiRolePermissionRolesWithPermissionsByCompanyGroupIdQuery()
    const [fetchPermissions, { data: permissions }] = useLazyGetApiRolePermissionPermissionsByCompanyGroupIdQuery()
    const [fetchRoles, { data: roles }] = useLazyGetApiRolePermissionRolesByCompanyGroupIdQuery()
    const [assignRole] = usePostApiRolePermissionAssignMutation()

    useEffect(() => {
        if (user) {
            fetchRolePermissionDatas()
            fetchPermissionDatas()
            fetchRoleDatas()
        }
    }, [user])

    const fetchRolePermissionDatas = async () => {
        try {
            dispatch(setLoading(true));

            await fetchRolePermissions(
                { companyGroupId: user?.company?.companyGroupId ?? "" }
            ).unwrap()
        } catch (err) {
            dispatch(setError(t('Roller ve Yetkiler yüklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const fetchPermissionDatas = async () => {
        try {
            dispatch(setLoading(true));

            await fetchPermissions(
                { companyGroupId: user?.company?.companyGroupId ?? "" }
            ).unwrap()
        } catch (err) {
            dispatch(setError(t('Yetkiler yüklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const fetchRoleDatas = async () => {
        try {
            dispatch(setLoading(true));

            await fetchRoles(
                { companyGroupId: user?.company?.companyGroupId ?? "" }
            ).unwrap()
        } catch (err) {
            dispatch(setError(t('Roller yüklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleRowClick = (record: any) => {
        setSelectedRole(record);
        setModalVisible(true);
        
        form.setFieldsValue({
            role: record.roleName,
            permissions: record.permissions.map((k: any) => k.id ?? "")
        });
    };

    const handleSave = async (values: any) => {
        var role = roles?.find(k => k.name == values.role)

        try {
            dispatch(setLoading(true));

            await assignRole(
                {
                    rolePermissionDto: {
                        roleId: role?.id ?? "",
                        permissionIds: values.permissions
                    }
                }
            ).unwrap()

            setModalVisible(false);
            setSelectedRole(null);
            fetchRolePermissionDatas();
        } catch (err) {
            dispatch(setError(t('Yetki atamasında hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    };

    const columns = [
        {
            title: 'Rol Adı',
            dataIndex: 'roleName',
            key: 'roleName',
            width: 200,
            render: (text: any, record: any) => {
                return {
                  props: {
                    style: { fontWeight: 'bold' },
                  },
                  children: <div>{text}</div>,
                };
              },
        },
        {
            title: 'Yetkiler',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (k: PermissionDto[]) => k?.flatMap(k => k?.name ?? "").join(', '),
        },
    ];

    return (
        <>
            <Table
                dataSource={rolePermissions}
                columns={columns}
                rowKey="role"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={false}
            />

            <Modal
                title="Rol Yetkilerini Düzenle"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setModalVisible(false)}>
                        İptal
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => form.submit()}>
                        Kaydet
                    </Button>,
                ]}
            >
                <Form form={form} onFinish={handleSave} layout="vertical">
                    <Form.Item name="role" label="Rol Adı">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="permissions" label="Yetkiler">
                        <Select mode="multiple" placeholder="Yetkileri seçin" style={{ width: '100%' }}>
                            {permissions?.map(k =>
                                (<Option value={k.id}>{k.name}</Option>)
                            )}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default SystemRoleManagement;
