// src/components/PerformanceGoalEntry/GoalTableSummary.tsx
import React from 'react';
import { Input, Table } from 'antd';

interface DepartmentOKRGoalTableSummaryProps {
  total: {
    weight: number;
  };
}

const DepartmentOKRGoalTableSummary: React.FC<DepartmentOKRGoalTableSummaryProps> = ({ total }) => {
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>Toplam</Table.Summary.Cell>
      <Table.Summary.Cell index={1} />
      <Table.Summary.Cell index={2}>
        <Input value={total.weight} suffix="%" disabled />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} />
      <Table.Summary.Cell index={4} />
    </Table.Summary.Row>
  );
};

export default DepartmentOKRGoalTableSummary;
