import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import BaseView from './components/BaseView';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <BaseView>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/">
              <Home />
            </PrivateRoute>
          </Switch>
        </BaseView>
      </Router>
    </Provider>
  );
};

export default App;
