import { Modal } from "antd";
import React, { useEffect } from 'react';
import { Form, Select, Input, DatePicker, Button, message } from 'antd';
import dayjs from 'dayjs';
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { usePostApiPerformanceCardsAddUserOkrMutation, UserOkrDetailsDto, UserOkrdto } from "../../../../api/services/performanceCards";
import { setLoading, setError } from "../../../../features/app/appSlice";
import { RootState } from "../../../../app/store";

const { Option } = Select;
const { TextArea } = Input;

export interface IOKRInputModal {
    isActive: boolean
    setActive: (status: boolean) => void
    cardId: string
    newOKRAdded: (okr: UserOkrdto) => void
    okrUpdated: (okr: UserOkrDetailsDto) => void
    initialValue: UserOkrDetailsDto | null
}

export interface OKRFormModel {
    okrCategory: 'Bireysel OKR' | 'Takım OKR' | 'Gelişim OKR';
    isLinkedToCompanyOKR: boolean;
    okrDescription: string;
    targetCompletionDate: Date;
    isPrivate: boolean;
}

const OKRInputModal: React.FC<IOKRInputModal> = ({ isActive, setActive, cardId, newOKRAdded, okrUpdated, initialValue }) => {
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [AddOKROnService] = usePostApiPerformanceCardsAddUserOkrMutation()

    const AddOKR = async (values: any) => {
        try {
            dispatch(setLoading(true));

            var payload: UserOkrdto = {
                id: initialValue != null ? initialValue.okrId : null,
                categoryId: "93c61149-7817-4320-bdfd-7bc24da25cbd",
                description: values.okrDescription ?? "",
                isLinked: values.isLinkedToCompanyOKR ?? "",
                isPrivate: values.isPrivate ?? "",
                isManagerApproved: true,
                performanceCardId: cardId,
                targetCompletionDate: values.targetCompletionDate,
            }
            await AddOKROnService({
                userOkrdto: payload
            }).unwrap()

            if (initialValue == null) {
                newOKRAdded(payload)
            } else {
                okrUpdated({
                    ...payload,
                    okrId: initialValue.okrId,
                    createdDate: initialValue.createdDate
                })
            }

        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('OKR eklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleSave = (values: any) => {
        console.log('Form Values:', values);
        AddOKR(values);
    };

    const handleCancel = () => {
        form.resetFields();
        setActive(false)
    };

    useEffect(() => {
        if (isActive == false) {
            form.resetFields();
        }
    }, [isActive])


    // Varsayılan değerleri tanımlıyoruz
    const initialValues = {
        okrCategory: initialValue != null ? initialValue.categoryId : 'Bireysel OKR',
        isLinkedToCompanyOKR: initialValue != null ? initialValue.isLinked : false,
        okrDescription: initialValue != null ? initialValue.description : '',
        targetCompletionDate: initialValue != null ? dayjs(initialValue.targetCompletionDate) : dayjs().add(1, 'week'),
        isPrivate: initialValue != null ? initialValue.isPrivate : false,
    };

    return (
        <>
            <Modal
                title={"Yeni OKR Ekleme"}
                visible={isActive}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={700}
            >

                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={handleSave}
                    style={{ maxWidth: 600, margin: 'auto' }}
                >
                    {/* OKR Kategori */}
                    <Form.Item
                        name="okrCategory"
                        label="OKR Kategori"
                        rules={[{ required: true, message: 'OKR Kategorisi seçilmelidir!' }]}
                    >
                        <Select placeholder="Kategori seçin">
                            <Option value="Bireysel OKR">Bireysel OKR</Option>
                            <Option value="Takım OKR">Takım OKR</Option>
                            <Option value="Gelişim OKR">Gelişim OKR</Option>
                        </Select>
                    </Form.Item>

                    {/* Şirket OKR'si ile link var mı? */}
                    <Form.Item
                        name="isLinkedToCompanyOKR"
                        label="Şirket OKR'si ile link var mı?"
                        rules={[{ required: true, message: 'Bu alan seçilmelidir!' }]}
                    >
                        <Select placeholder="Link durumu seçin">
                            <Option value={true}>Evet</Option>
                            <Option value={false}>Hayır</Option>
                        </Select>
                    </Form.Item>

                    {/* OKR Açıklaması */}
                    <Form.Item
                        name="okrDescription"
                        label="OKR Açıklaması"
                        rules={[{ required: true, message: 'Açıklama girilmelidir!' }]}
                    >
                        <TextArea rows={4} placeholder="OKR açıklamasını girin" maxLength={500} />
                    </Form.Item>

                    {/* Hedef Tamamlama Zamanı */}
                    <Form.Item
                        name="targetCompletionDate"
                        label="Hedef Tamamlama Zamanı"
                        rules={[{ required: true, message: 'Hedef tamamlama tarihi seçilmelidir!' }]}
                    >
                        <DatePicker
                            placeholder="Tarih seçin"
                            disabledDate={(date) => date.isBefore(dayjs()) || date.isAfter(dayjs().add(3, 'month'))}
                        />
                    </Form.Item>

                    {/* Gizli OKR mı? */}
                    <Form.Item
                        name="isPrivate"
                        label="Gizli OKR mı?"
                        rules={[{ required: true, message: 'Bu alan seçilmelidir!' }]}
                    >
                        <Select placeholder="Gizlilik durumu seçin">
                            <Option value={true}>Evet</Option>
                            <Option value={false}>Hayır</Option>
                        </Select>
                    </Form.Item>

                    {/* Kaydet ve İptal Butonları */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                            İptal
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Kaydet
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
}


export default OKRInputModal;
