// helpers.ts

import { DataItem } from "../../models/DataItem";


export const findNameById = (id: string, array: DataItem[]): string => {
  const item = array.find(element => element.id === id);
  return item ? item.name : 'Unknown';
};

export const findNameByRangeId = (id: number, array: DataItem[]): string => {
  const item = array.find(element => element.rangeType === id);
  return item ? item.name : 'Unknown';
};
