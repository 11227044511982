import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiPeriodSave: build.mutation<
      PostApiPeriodSaveApiResponse,
      PostApiPeriodSaveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Period/save`,
        method: "POST",
        body: queryArg.periodDto,
      }),
    }),
    getApiPeriodById: build.query<
      GetApiPeriodByIdApiResponse,
      GetApiPeriodByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Period/${queryArg.id}` }),
    }),
    getApiPeriodAll: build.query<
      GetApiPeriodAllApiResponse,
      GetApiPeriodAllApiArg
    >({
      query: () => ({ url: `/api/Period/all` }),
    }),
    getApiPeriodGetActivePeriods: build.query<
      GetApiPeriodGetActivePeriodsApiResponse,
      GetApiPeriodGetActivePeriodsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Period/GetActivePeriods`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiPeriodSaveApiResponse = unknown;
export type PostApiPeriodSaveApiArg = {
  periodDto: PeriodDto;
};
export type GetApiPeriodByIdApiResponse = /** status 200 OK */ PeriodDto;
export type GetApiPeriodByIdApiArg = {
  id: string;
};
export type GetApiPeriodAllApiResponse = /** status 200 OK */ PeriodDto[];
export type GetApiPeriodAllApiArg = void;
export type GetApiPeriodGetActivePeriodsApiResponse =
  /** status 200 OK */ PeriodDto[];
export type GetApiPeriodGetActivePeriodsApiArg = {
  companyGroupId?: string;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export const {
  usePostApiPeriodSaveMutation,
  useGetApiPeriodByIdQuery,
  useLazyGetApiPeriodByIdQuery,
  useGetApiPeriodAllQuery,
  useLazyGetApiPeriodAllQuery,
  useGetApiPeriodGetActivePeriodsQuery,
  useLazyGetApiPeriodGetActivePeriodsQuery,
} = injectedRtkApi;
