import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCompany: build.query<GetApiCompanyApiResponse, GetApiCompanyApiArg>({
      query: () => ({ url: `/api/Company` }),
    }),
    postApiCompany: build.mutation<
      PostApiCompanyApiResponse,
      PostApiCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company`,
        method: "POST",
        body: queryArg.createCompanyDto,
      }),
    }),
    getApiCompanyById: build.query<
      GetApiCompanyByIdApiResponse,
      GetApiCompanyByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Company/${queryArg.id}` }),
    }),
    putApiCompanyById: build.mutation<
      PutApiCompanyByIdApiResponse,
      PutApiCompanyByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateCompanyDto,
      }),
    }),
    deleteApiCompanyById: build.mutation<
      DeleteApiCompanyByIdApiResponse,
      DeleteApiCompanyByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiCompanyByGroupByGroupId: build.query<
      GetApiCompanyByGroupByGroupIdApiResponse,
      GetApiCompanyByGroupByGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Company/byGroup/${queryArg.groupId}`,
      }),
    }),
    getApiCompanyGroup: build.query<
      GetApiCompanyGroupApiResponse,
      GetApiCompanyGroupApiArg
    >({
      query: () => ({ url: `/api/CompanyGroup` }),
    }),
    postApiCompanyGroup: build.mutation<
      PostApiCompanyGroupApiResponse,
      PostApiCompanyGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompanyGroup`,
        method: "POST",
        body: queryArg.createCompanyGroupDto,
      }),
    }),
    getApiCompanyGroupById: build.query<
      GetApiCompanyGroupByIdApiResponse,
      GetApiCompanyGroupByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/CompanyGroup/${queryArg.id}` }),
    }),
    putApiCompanyGroupById: build.mutation<
      PutApiCompanyGroupByIdApiResponse,
      PutApiCompanyGroupByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompanyGroup/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateCompanyGroupDto,
      }),
    }),
    deleteApiCompanyGroupById: build.mutation<
      DeleteApiCompanyGroupByIdApiResponse,
      DeleteApiCompanyGroupByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompanyGroup/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiDepartmentOkrGetDepartmentOkRsByCompanyGroupId: build.query<
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse,
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/GetDepartmentOKRsByCompanyGroupId`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    getApiDialogParametersByCompanyGroupId: build.query<
      GetApiDialogParametersByCompanyGroupIdApiResponse,
      GetApiDialogParametersByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Dialog/parameters/${queryArg.companyGroupId}`,
      }),
    }),
    getApiPerformanceGoalGetPerformanceGoalsByCompanyGroupId: build.query<
      GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiResponse,
      GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceGoal/GetPerformanceGoalsByCompanyGroupId`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    getApiRolePermissionRolesByCompanyGroupId: build.query<
      GetApiRolePermissionRolesByCompanyGroupIdApiResponse,
      GetApiRolePermissionRolesByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles/${queryArg.companyGroupId}`,
      }),
    }),
    getApiRolePermissionPermissionsByCompanyGroupId: build.query<
      GetApiRolePermissionPermissionsByCompanyGroupIdApiResponse,
      GetApiRolePermissionPermissionsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/permissions/${queryArg.companyGroupId}`,
      }),
    }),
    getApiRolePermissionRolesWithPermissionsByCompanyGroupId: build.query<
      GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiResponse,
      GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-permissions/${queryArg.companyGroupId}`,
      }),
    }),
    postApiRolePermissionAssignUsersToRoleByCompanyGroupId: build.mutation<
      PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiResponse,
      PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/assign-users-to-role/${queryArg.companyGroupId}`,
        method: "POST",
        body: queryArg.roleAssignmentDto,
      }),
    }),
    getApiRolePermissionRolesWithUsersByCompanyGroupId: build.query<
      GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiResponse,
      GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/RolePermission/roles-with-users/${queryArg.companyGroupId}`,
      }),
    }),
    getApiStrategicOkrGetStrategicOkRsByCompanyGroupId: build.query<
      GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiResponse,
      GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/StrategicOKR/GetStrategicOKRsByCompanyGroupId`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    getApiTargetAudienceCompanyByCompanyIdTargetAudiences: build.query<
      GetApiTargetAudienceCompanyByCompanyIdTargetAudiencesApiResponse,
      GetApiTargetAudienceCompanyByCompanyIdTargetAudiencesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/TargetAudience/company/${queryArg.companyId}/target-audiences`,
      }),
    }),
    getApiUserByCompanyByCompanyId: build.query<
      GetApiUserByCompanyByCompanyIdApiResponse,
      GetApiUserByCompanyByCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompany/${queryArg.companyId}`,
      }),
    }),
    getApiUserByCompanyGroupByCompanyGroupId: build.query<
      GetApiUserByCompanyGroupByCompanyGroupIdApiResponse,
      GetApiUserByCompanyGroupByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/User/byCompanyGroup/${queryArg.companyGroupId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiCompanyApiResponse = /** status 200 OK */ CompanyDto[];
export type GetApiCompanyApiArg = void;
export type PostApiCompanyApiResponse = /** status 200 OK */ CompanyDto;
export type PostApiCompanyApiArg = {
  createCompanyDto: CreateCompanyDto;
};
export type GetApiCompanyByIdApiResponse = /** status 200 OK */ CompanyDto;
export type GetApiCompanyByIdApiArg = {
  id: string;
};
export type PutApiCompanyByIdApiResponse = unknown;
export type PutApiCompanyByIdApiArg = {
  id: string;
  updateCompanyDto: UpdateCompanyDto;
};
export type DeleteApiCompanyByIdApiResponse = unknown;
export type DeleteApiCompanyByIdApiArg = {
  id: string;
};
export type GetApiCompanyByGroupByGroupIdApiResponse =
  /** status 200 OK */ CompanyDto[];
export type GetApiCompanyByGroupByGroupIdApiArg = {
  groupId: string;
};
export type GetApiCompanyGroupApiResponse =
  /** status 200 OK */ CompanyGroupDto[];
export type GetApiCompanyGroupApiArg = void;
export type PostApiCompanyGroupApiResponse =
  /** status 200 OK */ CompanyGroupDto;
export type PostApiCompanyGroupApiArg = {
  createCompanyGroupDto: CreateCompanyGroupDto;
};
export type GetApiCompanyGroupByIdApiResponse =
  /** status 200 OK */ CompanyGroupDto;
export type GetApiCompanyGroupByIdApiArg = {
  id: string;
};
export type PutApiCompanyGroupByIdApiResponse = unknown;
export type PutApiCompanyGroupByIdApiArg = {
  id: string;
  updateCompanyGroupDto: UpdateCompanyGroupDto;
};
export type DeleteApiCompanyGroupByIdApiResponse = unknown;
export type DeleteApiCompanyGroupByIdApiArg = {
  id: string;
};
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse =
  /** status 200 OK */ DepartmentOkrMasterDto[];
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg = {
  companyGroupId?: string;
};
export type GetApiDialogParametersByCompanyGroupIdApiResponse = unknown;
export type GetApiDialogParametersByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiResponse =
  /** status 200 OK */ PerformanceGoalDto[];
export type GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiArg = {
  companyGroupId?: string;
};
export type GetApiRolePermissionRolesByCompanyGroupIdApiResponse =
  /** status 200 OK */ RoleDto[];
export type GetApiRolePermissionRolesByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type GetApiRolePermissionPermissionsByCompanyGroupIdApiResponse =
  /** status 200 OK */ PermissionDto[];
export type GetApiRolePermissionPermissionsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiResponse =
  /** status 200 OK */ RoleWithPermissionsDto[];
export type GetApiRolePermissionRolesWithPermissionsByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiResponse =
  unknown;
export type PostApiRolePermissionAssignUsersToRoleByCompanyGroupIdApiArg = {
  companyGroupId: string;
  roleAssignmentDto: RoleAssignmentDto;
};
export type GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiResponse =
  /** status 200 OK */ RoleWithUsersDto[];
export type GetApiRolePermissionRolesWithUsersByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiResponse =
  /** status 200 OK */ StrategicOkrMasterDto[];
export type GetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdApiArg = {
  companyGroupId?: string;
};
export type GetApiTargetAudienceCompanyByCompanyIdTargetAudiencesApiResponse =
  /** status 200 OK */ TargetAudienceDto[];
export type GetApiTargetAudienceCompanyByCompanyIdTargetAudiencesApiArg = {
  companyId: string;
};
export type GetApiUserByCompanyByCompanyIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyByCompanyIdApiArg = {
  companyId: string;
};
export type GetApiUserByCompanyGroupByCompanyGroupIdApiResponse =
  /** status 200 OK */ UserDto[];
export type GetApiUserByCompanyGroupByCompanyGroupIdApiArg = {
  companyGroupId: string;
};
export type CompanyGroupDto = {
  id?: string;
  groupName?: string | null;
};
export type CompanyDto = {
  id?: string;
  name?: string | null;
  address?: string | null;
  email?: string | null;
  companyGroupId?: string;
  companyGroup?: CompanyGroupDto;
};
export type CreateCompanyDto = {
  name?: string | null;
  address?: string | null;
  email?: string | null;
  companyGroupId?: string;
};
export type UpdateCompanyDto = {
  name?: string | null;
  address?: string | null;
  email?: string | null;
  companyGroupId?: string;
};
export type CreateCompanyGroupDto = {
  groupName?: string | null;
};
export type UpdateCompanyGroupDto = {
  groupName?: string | null;
};
export type DepartmentOkrDetailDto = {
  strategicOKRDetailId?: string;
  departmentOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetailDto = {
  strategicOKR?: string | null;
  strategicOKRId?: string;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
  period?: PeriodDto;
};
export type DepartmentOkrMasterDto = {
  id?: string;
  strategicOKRId?: string;
  companyGroupId?: string;
  functionId?: string;
  createdAt?: string;
  okrDetails?: DepartmentOkrDetailDto[] | null;
  strategicOKRDetails?: StrategicOkrDetailDto[] | null;
};
export type PerformanceGoalDetailDto = {
  id?: string;
  strategicGoal?: string | null;
  kpi?: string | null;
  weight?: number;
  source?: string;
  minResult?: number;
  targetResult?: number;
  maxResult?: number;
  createdAt?: string;
};
export type PerformanceGoalDto = {
  id?: string;
  companyGroupId?: string;
  periodId?: string;
  createdAt?: string;
  period?: PeriodDto;
  goalDetails?: PerformanceGoalDetailDto[] | null;
};
export type RoleDto = {
  id?: string;
  name?: string | null;
};
export type PermissionDto = {
  id?: string;
  name?: string | null;
};
export type RoleWithPermissionsDto = {
  roleId?: string;
  roleName?: string | null;
  permissions?: PermissionDto[] | null;
};
export type FilterDto = {
  action?: string | null;
  variable?: string | null;
  value?: string[] | null;
};
export type RoleAssignmentDto = {
  roleId?: string;
  filters?: FilterDto[] | null;
};
export type RoleWithUsersDto = {
  roleId?: string;
  roleName?: string | null;
  userCount?: number;
  formula?: FilterDto[] | null;
};
export type PerformanceSystemType = 1 | 2;
export type OkrDetailDto = {
  id?: string;
  okrShapeId?: string;
  okrRelationshipId?: string;
  okrTypeIds?: string[] | null;
  okrWeightId?: string;
  okrPrivacyId?: string;
  okrTransparencyId?: string;
  okrProgressId?: string;
  minValue?: number;
  maxValue?: number;
};
export type KpiDetailDto = {
  id?: string;
  kpiRelationshipId?: string;
  targetTypesIds?: string[] | null;
  compentencyId?: string;
  goalMeasurementTypeId?: string;
  goalTargetMeasurementSourceIds?: string[] | null;
};
export type KpiGoalCompentencyDetailsDto = {
  id?: string;
  performanceSystemId?: string;
  role?: string;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
};
export type ParameterType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19;
export type Parameter = {
  id?: string;
  parameterType?: ParameterType;
  companyGroupId?: string;
  companyId?: string;
  parameterGroup?: string | null;
  parameterValue?: string | null;
  parameterCode?: string | null;
};
export type OkrDetail = {
  id?: string;
  performanceSystemId?: string;
  okrShape?: Parameter;
  okrRelationship?: Parameter;
  okrType?: Parameter[] | null;
  okrWeight?: Parameter;
  okrPrivacy?: Parameter;
  okrTransparency?: Parameter;
  okrProgress?: Parameter;
  performanceSystem?: PerformanceSystem;
};
export type KpiDetail = {
  id?: string;
  performanceSystemId?: string;
  kpiRelationship?: Parameter;
  targetTypes?: Parameter[] | null;
  compentency?: Parameter;
  goalMeasurementType?: Parameter;
  goalTargetMeasurementSource?: Parameter[] | null;
  performanceSystem?: PerformanceSystem;
};
export type KpiGoalCompentencyDetails = {
  id?: string;
  performanceSystemId?: string;
  role?: Parameter;
  goalPercentage?: number;
  competencyPercentage?: number;
  corporateGoals?: number;
  departmentGoals?: number;
  individualGoals?: number;
  managerialCompetencies?: number;
  coreCompetencies?: number;
  performanceSystem?: PerformanceSystem;
};
export type RangeType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;
export type PerformanceSystemRange = {
  id?: string;
  performanceSystemId?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
  performanceSystem?: PerformanceSystem;
};
export type PerformanceSystem = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetail[] | null;
  kpiDetails?: KpiDetail[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetails[] | null;
  performanceSystemRanges?: PerformanceSystemRange[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  companyGroupId?: string;
};
export type Period = {
  id?: string;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetail = {
  id?: string;
  strategicOKRMasterId?: string;
  strategicOKRMaster?: StrategicOkrMaster;
  strategicOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
};
export type StrategicOkrMaster = {
  id?: string;
  companyGroupId?: string;
  performanceSystemId?: string;
  performancePeriodId?: string;
  createdAt?: string;
  performanceSystem?: PerformanceSystem;
  performancePeriod?: Period;
  strategicOKRDetails?: StrategicOkrDetail[] | null;
};
export type PerformanceSystemRangeDto = {
  id?: string;
  rangeType?: RangeType;
  minValue?: number;
  maxValue?: number;
};
export type PerformanceSystemDto = {
  id?: string;
  systemNumber?: string | null;
  systemName?: string | null;
  performanceSystemType?: PerformanceSystemType;
  okrDetails?: OkrDetailDto[] | null;
  kpiDetails?: KpiDetailDto[] | null;
  kpiGoalCompentencyDetails?: KpiGoalCompentencyDetailsDto[] | null;
  strategicOKRs?: StrategicOkrMaster[] | null;
  performanceSystemRanges?: PerformanceSystemRangeDto[] | null;
  companyGroupId?: string;
};
export type StrategicOkrMasterDto = {
  id?: string;
  companyGroupId?: string;
  performanceSystem?: PerformanceSystemDto;
  period?: PeriodDto;
  createdAt?: string;
  deletable?: boolean;
  okrDetails?: StrategicOkrDetailDto[] | null;
};
export type TargetAudienceDto = {
  id?: string | null;
  companyId?: string;
  targetAudienceNo?: string | null;
  targetAudienceName?: string | null;
  companyGroupId?: string;
  filters?: FilterDto[] | null;
};
export type UserDto = {
  id?: string;
  userName?: string | null;
  companyId?: string;
  employeeNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  subFunction?: string | null;
  department?: string | null;
  subDepartment?: string | null;
  title?: string | null;
  titleGroup?: string | null;
  employeeType?: string | null;
  workLocation?: string | null;
  startDate?: string;
  managerName?: string | null;
  managerSurname?: string | null;
  email?: string | null;
  phone?: string | null;
  company?: CompanyDto;
};
export const {
  useGetApiCompanyQuery,
  useLazyGetApiCompanyQuery,
  usePostApiCompanyMutation,
  useGetApiCompanyByIdQuery,
  useLazyGetApiCompanyByIdQuery,
  usePutApiCompanyByIdMutation,
  useDeleteApiCompanyByIdMutation,
  useGetApiCompanyByGroupByGroupIdQuery,
  useLazyGetApiCompanyByGroupByGroupIdQuery,
  useGetApiCompanyGroupQuery,
  useLazyGetApiCompanyGroupQuery,
  usePostApiCompanyGroupMutation,
  useGetApiCompanyGroupByIdQuery,
  useLazyGetApiCompanyGroupByIdQuery,
  usePutApiCompanyGroupByIdMutation,
  useDeleteApiCompanyGroupByIdMutation,
  useGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  useGetApiDialogParametersByCompanyGroupIdQuery,
  useLazyGetApiDialogParametersByCompanyGroupIdQuery,
  useGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery,
  useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery,
  useGetApiRolePermissionRolesByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionRolesByCompanyGroupIdQuery,
  useGetApiRolePermissionPermissionsByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionPermissionsByCompanyGroupIdQuery,
  useGetApiRolePermissionRolesWithPermissionsByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionRolesWithPermissionsByCompanyGroupIdQuery,
  usePostApiRolePermissionAssignUsersToRoleByCompanyGroupIdMutation,
  useGetApiRolePermissionRolesWithUsersByCompanyGroupIdQuery,
  useLazyGetApiRolePermissionRolesWithUsersByCompanyGroupIdQuery,
  useGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery,
  useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery,
  useGetApiTargetAudienceCompanyByCompanyIdTargetAudiencesQuery,
  useLazyGetApiTargetAudienceCompanyByCompanyIdTargetAudiencesQuery,
  useGetApiUserByCompanyByCompanyIdQuery,
  useLazyGetApiUserByCompanyByCompanyIdQuery,
  useGetApiUserByCompanyGroupByCompanyGroupIdQuery,
  useLazyGetApiUserByCompanyGroupByCompanyGroupIdQuery,
} = injectedRtkApi;
