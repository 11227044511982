// src/components/PerformanceGoalEntry/PerformanceGoalEntryModal.tsx
import React, {useEffect, useState} from 'react';
import {Modal, Form, Button, Popover, FormInstance} from 'antd';
import DepartmentOKRGoalTable from './DepartmentOKRGoalTable';
import FormItemCombobox from '../../components/FormItemCombobox';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useLazyGetApiPeriodGetActivePeriodsQuery} from '../../api/services/period';
import {DataItem} from '../../models/DataItem';
import {t} from 'i18next';
import {useSelector, useDispatch} from 'react-redux';
import {setLoading, setError} from '../../features/app/appSlice';
import {RootState} from '../../app/store';
import {ParameterType, usePostApiParameterGetParametersMutation} from '../../api/services/parameters';
import {usePostApiPerformanceGoalSavePerformanceGoalsMutation} from '../../api/services/PerformanceGoal';
import {useLazyGetApiPerformanceSystemQuery} from '../../api/services/performanceSystem';
import {
    StrategicOkrMasterDto,
    useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery
} from '../../api/services/StrategicOKR';
import moment from 'moment';
import {
    DepartmentOkrDetailDto,
    usePostApiDepartmentOkrSaveDepartmentOkrMutation
} from '../../api/services/DepartmentOKR';

interface DepartmentOKREntryModalProps {
    visible: boolean;
    onClose: () => void;
    initialValues?: any;
    form: FormInstance
}

const DepartmentOKREntryModal: React.FC<DepartmentOKREntryModalProps> = ({initialValues, visible, onClose, form}) => {

    const systemType = Form.useWatch('systemType', form);
    const [fetchStrategicOKRs, {data: strategicOKRs}] = useLazyGetApiStrategicOkrGetStrategicOkRsByCompanyGroupIdQuery()
    const [getParameters, {data: parameters}] = usePostApiParameterGetParametersMutation()
    const [savePerformanceGoalOnService] = usePostApiDepartmentOkrSaveDepartmentOkrMutation()
    const [fetchPerformanceSystems, {data: performanceSystems}] = useLazyGetApiPerformanceSystemQuery()
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [SelectedPerformanceSystem, setSelectedPerformanceSystem] = useState<string | undefined>()
    const [selectedOKR, setSelectedOKR] = useState<StrategicOkrMasterDto | undefined>(initialValues)

    useEffect(() => {
        if (user) {
            getStrategicOKRs()
            getPerformanceSystems()
            getParameters({
                companyGroupId: user.company?.companyGroupId ?? "",
                body: [
                    7 as ParameterType, //HEDEF_OLCUM_KAYNAGI
                    0 as ParameterType
                ]
            })
        }
    }, [user])

    const getStrategicOKRs = async () => {
        try {
            dispatch(setLoading(true));
            await fetchStrategicOKRs({
                companyGroupId: user?.company?.companyGroupId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Dönemler sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const getPerformanceSystems = async () => {
        try {
            dispatch(setLoading(true));
            await fetchPerformanceSystems({
                companyGroupId: user?.company?.companyGroupId ?? ""
            }).unwrap()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans sistemleri sorgulanırken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const saveDepartmentOKR = async (values: any) => {
        try {
            debugger
            dispatch(setLoading(true));
            await savePerformanceGoalOnService({
                saveDepartmentOkrdto: {
                    companyGroupId: user?.company?.companyGroupId ?? "",
                    functionId: values.functionId ?? "",
                    strategicOKRId: values.okrId ?? "",

                    okrDetails: (values.rows ?? []).map((k: any) => ({
                        departmentOKR: k.departmentOkr,
                        strategicOKRDetailId: k.okr,
                        okrWeight: k.okrWeight,
                        targetResult: k.targetResult,
                        targetSource: k.targetSource
                    } as DepartmentOkrDetailDto))
                }
                // savePerformanceGoalDto: {
                //   companyGroupId: user?.company?.companyGroupId ?? "",
                //   rows: values.rows,
                //   period: values.periodId
                // }
            }).unwrap()

            debugger
            onClose()
        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('Performans hedefi kaydedilirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const onComplete = () => {
        form.submit()
    }

    const onSave = (values: any) => {
        saveDepartmentOKR(values)
    }
    const content = (
        <div style={{width: 300}}>
            <h4>Sayfa Bilgisi</h4>
            <p>Bu sayfada performans sistemlerini tanımlayabilir ve yönetebilirsiniz.</p>
            <p>OKR veya KPI bazlı performans sistemlerini burada tanımlayarak, ilgili parametreleri
                belirleyebilirsiniz.</p>
            <p>Sistemin nasıl çalıştığını ve hangi parametrelerin gerekli olduğunu burada yapılandırabilirsiniz.</p>
        </div>
    );

    return (
        <Modal
            maskClosable={false}
            visible={visible}
            title={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>{initialValues?.okrId != undefined ? "Departman OKR Güncelle" : "Departman OKR Girişi"}</span>
                    <Popover content={content} title="Sayfa Bilgisi" trigger="click">
                        <Button
                            shape="circle"
                            icon={<InfoCircleOutlined/>}
                            style={{
                                marginRight: 30,
                                marginTop: -8,
                                zIndex: 1000,
                            }}
                        />
                    </Popover>
                </div>
            }
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    İptal
                </Button>,
                <Button key="save" type="primary" onClick={onComplete}>
                    Kaydet
                </Button>,
            ]}
            width={1200}
            bodyStyle={{maxHeight: '70vh', overflowY: 'auto'}}
        >
            <Form form={form} layout="vertical"
                  onFinish={onSave}
                  initialValues={{...initialValues, rows: initialValues?.goalDetails ?? null}}>
                <div className='flex flex-row'>
                    <FormItemCombobox name="okrId" label="Stratejik OKR'lar" placeholder="Stratejik OKR Seçiniz"
                                      datas={(strategicOKRs ?? []).map(k => ({
                                          id: k.id,
                                          name: `${k.period?.periodName} (${moment(k.period?.startDate).format("MM/DD/YYYY")}-${moment(k.period?.endDate).format("MM/DD/YYYY")})`
                                      } as DataItem))}
                                      style={{width: "300px"}}
                                      onChange={k => {
                                          var okr = strategicOKRs?.find(k => k.id == k.id)
                                          setSelectedOKR(okr)
                                      }}/>
                    <FormItemCombobox name="functionId"
                                      label="Fonksiyon"
                                      placeholder="Fonksiyon Seçiniz"
                                      onChange={(e: DataItem) => {
                                          setSelectedPerformanceSystem(e.id)
                                      }}
                                      datas={(parameters ?? []).filter(k => k.parameterCode == "Function").map(k => ({
                                          id: k.id,
                                          name: k.parameterValue
                                      } as DataItem))}
                                      style={{width: "300px", marginLeft: 20}}/>
                </div>
                <DepartmentOKRGoalTable
                    goal={initialValues}
                    selectedOKR={selectedOKR}
                    selectedPerformanceSystem={(performanceSystems ?? []).find(k => k.id == SelectedPerformanceSystem)?.okrDetails?.[0].okrWeightId}/>
            </Form>
        </Modal>
    );
};

export default DepartmentOKREntryModal;
