// src/components/PerformanceGoalEntry/GoalTableSummary.tsx
import React from 'react';
import { Input, Table } from 'antd';
import { CalculatedFields } from './StrategicOKRGoalTable';

interface StrategicOKRGoalTableSummaryProps {
  total: CalculatedFields
}

const StrategicOKRGoalTableSummary: React.FC<StrategicOKRGoalTableSummaryProps> = ({ total }) => {
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>Toplam</Table.Summary.Cell>
      <Table.Summary.Cell index={1} >
        <Input value={total.weight} suffix="%" disabled />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2}/>
      <Table.Summary.Cell index={3} />
    </Table.Summary.Row>
  );
};

export default StrategicOKRGoalTableSummary;
