import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiDepartmentOkrGetDepartmentOkRsByCompanyGroupId: build.query<
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse,
      GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/GetDepartmentOKRsByCompanyGroupId`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
    postApiDepartmentOkrSaveDepartmentOkr: build.mutation<
      PostApiDepartmentOkrSaveDepartmentOkrApiResponse,
      PostApiDepartmentOkrSaveDepartmentOkrApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/SaveDepartmentOKR`,
        method: "POST",
        body: queryArg.saveDepartmentOkrdto,
      }),
    }),
    putApiDepartmentOkrUpdateDepartmentOkrById: build.mutation<
      PutApiDepartmentOkrUpdateDepartmentOkrByIdApiResponse,
      PutApiDepartmentOkrUpdateDepartmentOkrByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/DepartmentOKR/UpdateDepartmentOKR/${queryArg.id}`,
        method: "PUT",
        body: queryArg.saveDepartmentOkrdto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiResponse =
  /** status 200 OK */ DepartmentOkrMasterDto[];
export type GetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdApiArg = {
  companyGroupId?: string;
};
export type PostApiDepartmentOkrSaveDepartmentOkrApiResponse = unknown;
export type PostApiDepartmentOkrSaveDepartmentOkrApiArg = {
  saveDepartmentOkrdto: SaveDepartmentOkrdto;
};
export type PutApiDepartmentOkrUpdateDepartmentOkrByIdApiResponse = unknown;
export type PutApiDepartmentOkrUpdateDepartmentOkrByIdApiArg = {
  id: string;
  saveDepartmentOkrdto: SaveDepartmentOkrdto;
};
export type DepartmentOkrDetailDto = {
  strategicOKRDetailId?: string;
  departmentOKR?: string | null;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export type StrategicOkrDetailDto = {
  strategicOKR?: string | null;
  strategicOKRId?: string;
  okrWeight?: number | null;
  targetSource?: string | null;
  targetResult?: number | null;
  period?: PeriodDto;
};
export type DepartmentOkrMasterDto = {
  id?: string;
  strategicOKRId?: string;
  companyGroupId?: string;
  functionId?: string;
  createdAt?: string;
  okrDetails?: DepartmentOkrDetailDto[] | null;
  strategicOKRDetails?: StrategicOkrDetailDto[] | null;
};
export type SaveDepartmentOkrdto = {
  strategicOKRId?: string;
  companyGroupId?: string;
  functionId?: string;
  okrDetails?: DepartmentOkrDetailDto[] | null;
};
export const {
  useGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  useLazyGetApiDepartmentOkrGetDepartmentOkRsByCompanyGroupIdQuery,
  usePostApiDepartmentOkrSaveDepartmentOkrMutation,
  usePutApiDepartmentOkrUpdateDepartmentOkrByIdMutation,
} = injectedRtkApi;
