import React, {useEffect, useState} from 'react';
import {Table, Button, Input, Select, Form} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import DepartmentOKRGoalTableRow from './DepartmentOKRGoalTableRow';
import DepartmentOKRGoalTableSummary from './DepartmentOKRGoalTableSummary';
import TextArea from 'antd/es/input/TextArea';
import {kpiGoalCalculationSource} from '../../data/data';
import {DataItem} from '../../models/DataItem';
import {PerformanceGoalDetailDto, PerformanceGoalDto} from '../../api/services/PerformanceGoal';
import {StrategicOkrMasterDto} from '../../api/services/company';
import {StrategicOkrDetailDto} from '../../api/services/StrategicOKR';

const initialRows: PerformanceGoalDetailDto[] = [
    {strategicGoal: '', kpi: '', weight: 0, source: '', minResult: 0, targetResult: 0, maxResult: 0},
];


export interface DepartmentOKRGoalTableParams {
    goal: any | undefined
    selectedPerformanceSystem: string | undefined
    selectedOKR: StrategicOkrMasterDto | undefined
}

const DepartmentOKRGoalTable: React.FC<DepartmentOKRGoalTableParams> = ({
                                                                            goal,
                                                                            selectedPerformanceSystem,
                                                                            selectedOKR
                                                                        }) => {
    const [dataSource, setDataSource] = useState<any[]>(goal?.okrDetails ?? initialRows);
    let OKRWeightId = '6ad87b41-2e7c-49ad-9919-f997c4b03807'

    useEffect(() => {
        if(selectedOKR) {
            debugger
        }
    }, [selectedOKR])

    useEffect(() => {
        if (goal?.okrDetails) {
            console.log(goal?.okrDetails)
            setDataSource(goal?.okrDetails)
        }
    }, [goal?.okrDetails])

    useEffect(() => {

        console.log(selectedOKR)

    }, [selectedOKR])

    const handleAddRow = () => {
        const newRow: PerformanceGoalDetailDto = {
            id: `${dataSource.length + 1}`,
            strategicGoal: '',
            kpi: '',
            weight: 0,
            source: '',
            minResult: 0,
            targetResult: 0,
            maxResult: 0,
        };
        setDataSource([...dataSource, newRow]);
    };

    const handleChange = (key: string, field: string, value: any) => {
        const newData = dataSource.map((row) => {
            if (row.id === key) {
                return {...row, [field]: value};
            }
            return row;
        });
        setDataSource(newData);
    };

    const calculateTotal = (field: keyof PerformanceGoalDetailDto) => {
        return 0 //dataSource.reduce((sum, row) => sum + (Number(row[field]) || 0), 0);
    };

    const totalRow = {
        weight: calculateTotal('weight'),
    };

    const columns = [
        {
            title: 'Stratejik OKR',
            dataIndex: 'okr',
            key: 'okr',
            width: 400,
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    className='mb-0'
                    name={["rows", index, "okr"]}
                    // rules={[
                    //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
                    // ]}
                    initialValue={text}
                    style={{width: 400}}
                >
                    <Select
                        value={text}
                        onChange={(value) => handleChange(record.id ?? "", 'source', value)}
                        style={{height: '40px', width: "400px"}} // Select için yükseklik ayarı
                    >
                        {(selectedOKR?.okrDetails ?? []).map((item: StrategicOkrDetailDto) => (
                            <Select.Option key={item.strategicOKRId} value={item.strategicOKRId}>
                                {item.strategicOKR}
                            </Select.Option>
                        ))}
                    </Select>

                </Form.Item>

            ),
        },
        {
            title: 'Departman OKR\'ı',
            dataIndex: 'departmentOkr',
            key: 'departmentOkr',
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    className='mb-0'
                    name={["rows", index, "departmentOkr"]}
                    // rules={[
                    //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
                    // ]}
                    initialValue={text}
                >
                    <TextArea
                        value={text}
                        onChange={(e) => handleChange(record.id ?? "", 'departmentOkr', e.target.value)}
                        style={{height: '40px', resize: 'none'}} // TextArea için yükseklik ayarı
                    />
                </Form.Item>

            ),
        },
        {
            hidden: selectedPerformanceSystem != OKRWeightId,
            title: 'OKR Ağırlık %',
            dataIndex: 'weight',
            key: 'weight',
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    className='mb-0'
                    name={["rows", index, "weight"]}
                    // rules={[
                    //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
                    // ]}
                    initialValue={text}
                >
                    <Input
                        type="number"
                        min={0}
                        max={100}
                        value={text}
                        onChange={(e) => handleChange(record.id ?? "", 'weight', parseFloat(e.target.value))}
                        suffix="%"
                        style={{height: '40px'}} // Input için yükseklik ayarı
                    />
                </Form.Item>

            ),
        },
        {
            hidden: selectedPerformanceSystem != OKRWeightId,
            title: 'Hedef Ölçüm Kaynağı',
            dataIndex: 'goalMeasurementSource',
            key: 'goalMeasurementSource',
            render: (text: number, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    name={["rows", index, "goalMeasurementSource"]}
                    className='mb-0'
                    // rules={[
                    //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
                    // ]}
                    initialValue={text}
                >
                    <Select
                        value={text}
                        onChange={(value) => handleChange(record.id ?? "", 'source', value)}
                        style={{height: '40px'}} // Select için yükseklik ayarı
                    >
                        {kpiGoalCalculationSource.map((item: DataItem) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

            ),
        },
        {
            hidden: selectedPerformanceSystem != OKRWeightId,
            title: 'Hedef Ölçüm Kaynağı',
            dataIndex: 'source',
            key: 'source',
            render: (text: string, record: PerformanceGoalDetailDto, index: number) => (
                <Form.Item
                    name={["rows", index, "source"]}
                    className='mb-0'
                    // rules={[
                    //   { required: true, message: "Sonuç Açıklama yazmalısınız" }
                    // ]}
                    initialValue={text}
                >

                    <Input
                        type="number"
                        min={0}
                        max={100}
                        value={text}
                        onChange={(e) => handleChange(record.id ?? "", 'source', parseFloat(e.target.value))}
                        suffix="%"
                        style={{height: '40px'}} // Input için yükseklik ayarı
                    />
                </Form.Item>

            ),
        }
    ];


    return (
        <>
            <Button onClick={handleAddRow} type="primary" icon={<PlusOutlined/>} style={{marginBottom: 16}}>
                Satır Ekle
            </Button>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
                rowKey="key"
                summary={() => (selectedPerformanceSystem == OKRWeightId) ?
                    <DepartmentOKRGoalTableSummary total={totalRow}/> : null}
            />
        </>
    );
};

export default DepartmentOKRGoalTable;
