import React from 'react';
import { Form, Input, Slider, Button, message, SliderSingleProps } from 'antd';
import { Modal } from "antd";
import { usePostApiPerformanceCardsAddOrUpdateKrUpdateMutation, usePostApiPerformanceCardsAddOrUpdateUserKrMutation, UserKrDetailsDto, UserKrdto, UserKrUpdateDto } from '../../../../api/services/performanceCards';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../app/store';
import { t } from 'i18next';
import { setLoading, setError } from '../../../../features/app/appSlice';

const { TextArea } = Input;

export interface IProgressInputModal {
    isActive: boolean
    setActive: (status: boolean) => void
    initialProgress: number; // Başlangıç yüzdesi
    kr: UserKrDetailsDto | undefined
    newProgressAdded: (okr: UserKrDetailsDto) => void
    initialData: UserKrUpdateDto | undefined
}

export interface KRStatusUpdateModel {
    progressDescription: string;
    progressPercentage: number;
}

const ProgressInputModal: React.FC<IProgressInputModal> = ({ isActive, setActive, initialProgress, kr, newProgressAdded, initialData }) => {
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const [AddProgressOnService] = usePostApiPerformanceCardsAddOrUpdateKrUpdateMutation()


    const AddProgress = async (values: any) => {
        try {
            dispatch(setLoading(true));

            var payload: UserKrdto = {
                description: values.progressDescription,
                krId: kr?.krId ?? "",
                progressUpdate: values.progressPercentage ?? 0
            } as UserKrUpdateDto

            await AddProgressOnService({
                userKrUpdateDto: payload
            }).unwrap()


            newProgressAdded(payload)


        } catch (err: any) {
            if (err.status != "PARSING_ERROR")
                dispatch(setError(t('OKR eklenirken hata oluştu')));
        } finally {
            dispatch(setLoading(false));
        }
    }


    const handleSave = (values: any) => {
        console.log('Form Values:', values);
        AddProgress(values)
    };

    const handleCancel = () => {
        form.resetFields();
        setActive(false)
    };


    const marks: SliderSingleProps['marks'] = {
        0: '0 %',
        20: '20 %',
        40: '40 %',
        60: '60 %',
        80: '80 %',
        100: {
            style: { marginLeft: -10, width: 50 },
            label: '100 %',
        }
    };

    return (
        <>
            <Modal
                title={"Yeni KR Güncellemesi"}
                visible={isActive}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
                width={700}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSave}
                    style={{ maxWidth: 600, margin: 'auto' }}
                    initialValues={{
                        progressDescription: initialData != undefined ? initialData.description : '',
                        progressPercentage: initialData != undefined ? initialData.progressUpdate : initialProgress,
                    }}
                >
                    {/* İlerleme Açıklaması */}
                    <Form.Item
                        name="progressDescription"
                        label="İlerleme Açıklaması"
                        rules={[{ required: true, message: 'İlerleme açıklaması girilmelidir!' }]}
                    >
                        <TextArea rows={4} disabled={initialData != undefined} placeholder="İlerleme açıklamasını girin" maxLength={500} />
                    </Form.Item>

                    {/* İlerleme Oranı */}
                    <Form.Item
                        name="progressPercentage"
                        label="İlerleme Oranı"
                        rules={[{ required: true, message: 'İlerleme oranı girilmelidir!' }]}
                    >
                        <Slider
                            disabled={initialData != undefined}
                            className='mt-5'
                            defaultValue={initialProgress}
                            min={0}
                            max={100}
                            marks={marks}
                            dots={true}
                            step={10}
                            tooltip={{ formatter: (value) => `${value}%` }}
                        />
                    </Form.Item>
                    {initialData == undefined && <>
                        {/* Kaydet ve İptal Butonları */}
                        < div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                                İptal
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Kaydet
                            </Button>
                        </div>
                    </>
                    }
                </Form>
            </Modal >
        </>
    )
}


export default ProgressInputModal;
