import { coreApi as api } from "../core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiPerformanceGoalSavePerformanceGoals: build.mutation<
      PostApiPerformanceGoalSavePerformanceGoalsApiResponse,
      PostApiPerformanceGoalSavePerformanceGoalsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceGoal/SavePerformanceGoals`,
        method: "POST",
        body: queryArg.savePerformanceGoalDto,
        params: { id: queryArg.id },
      }),
    }),
    getApiPerformanceGoalGetPerformanceGoalsByCompanyGroupId: build.query<
      GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiResponse,
      GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PerformanceGoal/GetPerformanceGoalsByCompanyGroupId`,
        params: { companyGroupId: queryArg.companyGroupId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PostApiPerformanceGoalSavePerformanceGoalsApiResponse = unknown;
export type PostApiPerformanceGoalSavePerformanceGoalsApiArg = {
  id?: string;
  savePerformanceGoalDto: SavePerformanceGoalDto;
};
export type GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiResponse =
  /** status 200 OK */ PerformanceGoalDto[];
export type GetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdApiArg = {
  companyGroupId?: string;
};
export type PerformanceGoalRowDto = {
  strategicGoal?: string | null;
  kpi?: string | null;
  weight?: number;
  source?: string;
  minResult?: number;
  targetResult?: number;
  maxResult?: number;
  createdAt?: string;
};
export type SavePerformanceGoalDto = {
  period?: string;
  companyGroupId?: string;
  rows?: PerformanceGoalRowDto[] | null;
};
export type PeriodDto = {
  id?: string | null;
  periodNo?: string | null;
  periodName?: string | null;
  startDate?: string;
  endDate?: string;
  companyGroupId?: string;
};
export type PerformanceGoalDetailDto = {
  id?: string;
  strategicGoal?: string | null;
  kpi?: string | null;
  weight?: number;
  source?: string;
  minResult?: number;
  targetResult?: number;
  maxResult?: number;
  createdAt?: string;
};
export type PerformanceGoalDto = {
  id?: string;
  companyGroupId?: string;
  periodId?: string;
  createdAt?: string;
  period?: PeriodDto;
  goalDetails?: PerformanceGoalDetailDto[] | null;
};
export const {
  usePostApiPerformanceGoalSavePerformanceGoalsMutation,
  useGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery,
  useLazyGetApiPerformanceGoalGetPerformanceGoalsByCompanyGroupIdQuery,
} = injectedRtkApi;
