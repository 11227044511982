import { Button, Card, Progress, Slider } from "antd";
import { ActivePerformanceCardDto, PerformanceCardDetailsDto, UserOkrDetailsDto } from "../../../api/services/performanceCards";
import PerformanceCardOKR from "./PerformanceCardOKR";
import { PerformanceCardGroupType } from "../PerformanceCardGroupType";
import { useEffect, useState } from "react";
import OKRInputModal from "./Modal/OKRInputModal";
import moment from "moment";
import { LinkedOKRId, WeightenedOKRId } from "../../../data/constants";

export interface IPerformanceCardGroup {
    card: PerformanceCardDetailsDto | null
    CardDetails: PerformanceCardDetailsDto | undefined
    type: PerformanceCardGroupType
    needsRecalculation?: (okrs: UserOkrDetailsDto[]) => void
}

const PerformanceCardGroup: React.FC<IPerformanceCardGroup> = ({ type, card, CardDetails, needsRecalculation }) => {
    const [okrInputActive, setOkrInputActive] = useState(false)
    const [isWeightened, setIsWeightened] = useState(false)
    const [isLinked, setIsLinked] = useState(false)
    const [okrs, setOkrs] = useState<UserOkrDetailsDto[]>([])
    const [updateCard, setUpdateCard] = useState<UserOkrDetailsDto | null>(null)

    useEffect(() => {
        if (CardDetails) {
            var okrs: UserOkrDetailsDto[] = []
            if (type == PerformanceCardGroupType.Active) {
                okrs = CardDetails?.okRs?.filter(k => k.status == 0) ?? []
            } else if (type == PerformanceCardGroupType.Completed) {
                okrs = CardDetails?.okRs?.filter(k => k.status == 1) ?? []
            } else {
                okrs = CardDetails?.okRs?.filter(k => k.status == 2) ?? []
            }

            setOkrs(okrs)

            var okrWeightId = CardDetails.performanceSystem?.okrDetails?.[0].okrWeightId
            setIsWeightened(okrWeightId == WeightenedOKRId)

            var okrLinkedId = CardDetails.performanceSystem?.okrDetails?.[0].okrRelationshipId
            setIsLinked(okrLinkedId == LinkedOKRId)

        }
    }, [CardDetails])

    const getHeaderColor = () => {
        if (type == PerformanceCardGroupType.Active) {
            return "#69cbe4";
        } else if (type == PerformanceCardGroupType.Completed) {
            return "#5fbf6c";
        } else {
            return "#737373"
        }
    }

    const calculateTotalProgress = () => {
        var total = okrs.reduce((n, newVal, index) => (n + (newVal.progress ?? 0)), 0)
        return total / okrs.length
    }

    return (
        <div>
            <Card title={
                <div style={{ background: getHeaderColor() }} className="flex flex-row justify-between items-center rounded-md overflow-hidden">
                    <div className="flex flex-row h-[30px]">
                        {
                            okrs.length > 0 &&
                            <h2 className="text-white ml-3 mr-5">{type}</h2>
                        }
                        {
                            okrs.length == 0 &&
                            <h2 className="text-white ml-3 mr-5">Bir OKR ekleme ile başlayabilirsiniz</h2>
                        }
                        {isWeightened ? <Progress type="circle" strokeColor={"#FF0000"} trailColor="#00000030" percent={calculateTotalProgress()} size={40} className="absolute left-[150px] top-[0px]" />
                            : <></>}
                    </div>
                    <div className="flex flex-row items-center">
                        {
                            okrs.length > 0 &&
                            <Progress percent={calculateTotalProgress()} strokeColor={"#FF0000"} trailColor="#00000030" percentPosition={{ align: 'start', type: 'outer' }} className="w-[200px] mr-2" />
                        }

                        {!isLinked &&
                            <Button type="text" onClick={() => {
                                setOkrInputActive(true)
                            }} className="ml-2 text-white bg-red-500 border-0 rounded-none">
                                OKR Ekle
                            </Button>
                        }
                    </div>
                </div>
            }
                bordered={false}
                className="w-full"
                style={{ borderRadius: 0, boxShadow: "none" }}
                styles={{
                    body: {
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingTop: 0,
                        boxShadow: "none"
                    },

                    header: {
                        height: 30,
                        minHeight: 40,
                        padding: 0,
                        border: 0
                    }
                }} >
                {okrs
                    .sort(function (left, right) {
                        return moment.utc(left.createdDate).diff(moment.utc(right.createdDate))
                    })
                    .map((k: UserOkrDetailsDto, i) => (
                        <PerformanceCardOKR index={i} okr={k} CardDetails={CardDetails} update={() => {
                            setUpdateCard(k)
                            setOkrInputActive(true)
                        }} />
                    ))}
            </Card >
            <OKRInputModal
                initialValue={updateCard}
                isActive={okrInputActive}
                setActive={(status) => { setOkrInputActive(status); setUpdateCard(null) }}
                cardId={CardDetails?.cardId ?? ""}
                newOKRAdded={okr => {
                    setOkrs([...okrs, okr])
                    setOkrInputActive(false)
                    calculateTotalProgress()
                }}
                okrUpdated={okr => {
                    var oldOKR = okrs.find(k => k.okrId == okr.okrId)
                    var filteredOKRs = okrs.filter(k => k.okrId != okr.okrId)
                    okr.createdDate = oldOKR?.createdDate

                    setOkrs([...filteredOKRs, okr])
                    setOkrInputActive(false)
                    calculateTotalProgress()
                }}
            />
        </div >
    );
}

export default PerformanceCardGroup;
