import { Form, Select } from "antd";
import { CSSProperties } from "react";
import { DataItem } from "../models/DataItem";

interface FormItemComboboxProps {
    name: string,
    label: string,
    datas: DataItem[],
    onChange?: (item: DataItem) => void,
    multiSelectEnabled?: boolean
    style?: CSSProperties
    placeholder?: string
}

const FormItemCombobox: React.FC<FormItemComboboxProps> = ({ name, label, datas, onChange, placeholder = "", multiSelectEnabled = false, style = { width: "50%" } }) => {
    return (<>
        <Form.Item name={name} label={label} style={style}>
            <Select onChange={(e) => onChange ? onChange(datas.find(k => k.id === e)!) : {}}
                mode={multiSelectEnabled ? "multiple" : undefined}
                placeholder={placeholder}>
                {datas.map(data => (
                    <Select.Option key={data.id} value={data.id}>
                        {data.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    </>);
}

export default FormItemCombobox;